import {
  createCustomer,
  createCustomerWithMagicLinkVerification,
  getSignInData,
  getAccountStatus,
  login,
  resetPassword,
  loginWithMagicLink,
  verifyOneTimePassword,
  sendMagicLink
} from "@services/vueCustomerSvc";

import { extoleSignUp } from "@services/extoleSvc";
import segmentTracking from '@utilities/segmentTracking';

import sentry from "../../addSentry";
import { getObjProperty } from "@utilities/mrVueUtils";
import { required, minLength, maxLength } from '@vuelidate/validators';

const state = {
  view: 'signIn',
  processing: false,
  verifying: false,
  googleLoading: false,
  accountStatus: null,
  userEmail: null,
  userPassword: null,
  confirmNewPassword: null,
  passwordValidators: {
    required,
    minLength: minLength(8),
    maxLength: maxLength(64),
    containsUpperAndLowerCaseCharacters: function(password) {
      return /[A-Z]/g.test(password) && /[a-z]/g.test(password);
    },
    containsNumber: function(password) {
      return /[0-9]/g.test(password);
    },
    containsSymbol: function(password) {
      return /[!@#$%^&*()?\][{}+_\-=`~]/g.test(password);
    },
  },
  signInError: null,
  createError: null,
  keepMeSignedIn: true,
  captchaResponse: null,
  showCaptcha: false,
  code: null,
  magicCode: null,
  nextUrl: null,
  optInEmail: true,
  showOptIn: false,
  birthdayDay: null,
  birthdayMonth: null,
  onSkipCallback: null,
  accountCreatedInFlow: false,
  timedOut: false,
  onSuccessCallback: null,
  captchaSettings: {},
  successfullPasswordCreation: false,
  guestSignedIn: false
};

const getters = {
  birthday(state) {
    if (state.birthdayMonth && state.birthdayDay) {
      let month = ("0" + state.birthdayMonth).slice(-2);
      let day = ("0" + state.birthdayDay).slice(-2);
      return month + "/" + day + "/1896";
    } else {
      return null;
    }
  }
};

export const actions = {
  getAccountStatus({ state, commit }) {
    if (state.processing) {
      return;
    }
    commit('setProcessing', true);

    return getAccountStatus({ email: state.userEmail }).then(res => {
      let status = res.data.status;
      commit('setAccountStatus', status);

      if (status == 'no_account') {
        commit('setView', 'createAccount');
      } else if (status == 'no_password_created') {
        commit('setView', 'verifyAccount');
      } else if (status == 'password_created') {
        commit('setView', 'loginWithPassword');
      }

      commit('setProcessing', false);
    }).catch(() => {
      commit('setProcessing', false);
    });
  },

  signIn({ state, commit, dispatch }) {
    if (state.processing) {
      return;
    }

    commit('setProcessing', true);
    let payload = formatLoginPayload();

    return login(payload).then(() => {
      segmentTracking.trackMREvent('Logged in successfully');
      dispatch('afterSignin');
    }).catch(err => {
      dispatch('afterSignInError', { err });
    });
  },

  verifyAndSignIn({ state, commit, dispatch }, { oneTimePassword }) {
    if (state.verifying) {
      return;
    }

    commit('setVerifying', true);
    let payload = formatLoginPayload();
    payload.otp = oneTimePassword;

    return verifyOneTimePassword(payload).then(() => {
      dispatch('afterSignin');
    }).catch(err => {
      commit('setVerifying', false);
      dispatch('afterSignInError', { err });
    });
  },

  getSignInData({ commit }) {
    getSignInData().then(res => {
      if (res.data.email) {
        commit('setUserEmail', res.data.email);
        commit('setShowOptIn', false);
      }
      commit('setCaptchaSettings', res.data.captchaSettings);
    }).catch(err => {
      // send to sentry
      sentry.withScope(scope => {
        scope.setTag("vueComponent", "MrSignIn");
        sentry.captureException(err);
      });
    });
  },

  sendMagicLinkEmail({ state, commit, dispatch }, params = {}) {
    if (state.processing) {
      return;
    }

    let { email } = params;

    commit('setProcessing', true);

    return sendMagicLink({ email: email || state.userEmail }).then(() => {
      segmentTracking.trackMREvent('Magic Link Email sent');
      commit('setProcessing', false);
    }).catch(err => {
      const msg = getObjProperty(err, 'response.data.message');
      commit('setProcessing', false);
      dispatch('notifyError', msg, { root: true });
    });
  },

  createCustomer({ state, getters, commit, dispatch, rootGetters }) {
    commit('setProcessing', true);
    let payload = {
      email: state.userEmail,
      password: state.userPassword,
      confirm: state.userPassword,
      url: state.nextUrl,
      email_source: "website",
      optInEmail: state.optInEmail,
      birthday: getters.birthday,
      captchaResponse: state.captchaResponse,
      magicCode: state.magicCode,
      optInSmsTransactional: rootGetters["customer/originalOptInSmsTransactional"],
      optInSmsMarketing: rootGetters["customer/originalOptInSmsMarketing"],
    };

    let createFunction = state.magicCode ? createCustomerWithMagicLinkVerification : createCustomer;

    createFunction(payload).then((res) => {
      if (state.view == 'createPassword') {
        segmentTracking.trackMREvent('Created a password for existing account');
      } else {
        segmentTracking.trackMREvent('Created an account');
      }
      const customerId = getObjProperty(res, 'data.user.id');
      const email = getObjProperty(res, 'data.user.email');
      const verification = getObjProperty(res, 'data.user.verification');
      extoleSignUp(customerId, email);
      if (verification === 'pending') {
        commit('setProcessing', false);
        commit('setView', 'emailSent');
      } else {
        dispatch('afterSignin');
      }
    }).catch(err => {
      dispatch('afterSignUpError', { err });
    });
  },

  resetPassword({ state, commit, dispatch }) {
    // reset password and sign in
    commit('setProcessing', true);
    let payload = {
      account_email: state.userEmail,
      accountPassword: state.userPassword,
      accountPasswordConfirm: state.confirmNewPassword,
      code: state.code,
      captchaResponse: state.captchaResponse
    };

    if (state.view == 'setPassword') {
      payload.accountPasswordConfirm = payload.accountPassword;
    }

    return resetPassword(payload).then(() => {
      segmentTracking.trackMREvent('Password reset');
      commit('setProcessing', false);
      dispatch('signIn');
    }).catch(err => {
      commit('setProcessing', false);
      let error = err.response;

      if (error && error.data && error.data.code == 'CAPTCHA') {
        commit('setShowCaptcha', true);
      }

      dispatch('notifyError', error.data.message, { root: true });
    });
  },

  loginWithMagicLink({ state, commit, dispatch }) {
    return new Promise((resolve, reject) => {
      commit('setProcessing', true);
      let payload = {
        email: state.userEmail,
        magicCode: state.magicCode,
        captchaResponse: state.captchaResponse
      };

      return loginWithMagicLink(payload).then(() => {
        segmentTracking.trackMREvent('Login with magic link');
        commit('setProcessing', false);
        dispatch('afterSignin');
        resolve();
      }).catch(err => {
        commit('setProcessing', false);
        let error = err.response;
        commit('setView', 'signIn');
        dispatch('notifyError', error.data.message, { root: true });
        reject();
      });
    });
  },

  afterSignin({ commit, state }) {
    let url = '/';
    //- make sure user doesn't get stuck on signin in loop
    if (state.nextUrl && !state.nextUrl.toLowerCase().includes('signin')) {
      url = state.nextUrl.replace(/[:()<>]/g, '');
    }

    if (state.onSuccessCallback) {
      return state.onSuccessCallback();
    }

    window.location = url;

    commit('setGoogleLoading', false);
  },

  afterSignUpError({ commit, dispatch }, { err }) {
    let error = err.response;
    commit('setProcessing', false);
    let errorCode = getObjProperty(error, 'data.code');
    if (errorCode === 'MISSING_PASSWORD') {
      dispatch('sendMagicLinkEmail');
      commit('setView', 'emailSent');
    } else {
      if (errorCode == 'CAPTCHA') {
        commit('setShowCaptcha', true);
      }

      commit('setCreateError', error.data.message);

      if (error.data.validation) {
        let firstKey = Object.keys(error.data.validation)[0];
        commit('setCreateError', error.data.validation[firstKey]);
      }
    }
  },

  afterSignInError({ commit, dispatch }, { err }) {
    let error = err.response;
    commit('setProcessing', false);
    let errorCode = getObjProperty(error, 'data.code');
    if (errorCode == 'CAPTCHA') {
      commit('setShowCaptcha', true);
      commit('setCaptchaResponse', null);
    } else if (errorCode === 'MISSING_PASSWORD') {
      dispatch('sendMagicLinkEmail');
      commit('setView', 'emailSent');
    } else if (errorCode == 'REQUIRE_OTP') {
      commit('setView', 'oneTimePassword');
      dispatch('notifyWarn', error.data.message, { root: true });
    } else if (errorCode == 'CUSTOMER_NOT_VERIFIED') {
      commit('setView', 'magicLink');
      dispatch('notifyWarn', error.data.message, { root: true });
    } else {
      dispatch('notifyError', error.data.message, { root: true });
    }
  },
};

const mutations = {
  setProcessing(state, val) {
    state.processing = Boolean(val);
  },

  setVerifying(state, val) {
    state.verifying = Boolean(val);
  },

  setGoogleLoading(state, val) {
    state.googleLoading = Boolean(val);
  },

  setShowCaptcha(state, val) {
    state.showCaptcha = Boolean(val);
  },

  setCaptchaResponse(state, val) {
    state.captchaResponse = val;
  },

  setSignInError(state, val) {
    state.signInError = val;
  },

  setCreateError(state, val) {
    state.createError = val;
  },

  setAccountStatus(state, val) {
    state.accountStatus = val;
  },

  setUserEmail(state, val) {
    state.userEmail = val;
  },

  setUserPassword(state, val) {
    state.userPassword = val;
  },

  setConfirmNewPassword(state, val) {
    state.confirmNewPassword = val;
  },

  setKeepMeSignedIn(state, val) {
    state.keepMeSignedIn = Boolean(val);
  },

  setNextUrl(state, val) {
    state.nextUrl = val;
  },

  setOptInEmail(state, val) {
    state.optInEmail = Boolean(val);
  },

  setShowOptIn(state, val) {
    state.showOptIn = Boolean(val);
  },

  setCode(state, val) {
    state.code = val;
  },

  setMagicCode(state, val) {
    state.magicCode = val;
  },

  setBirthdayDay(state, val) {
    state.birthdayDay = val;
  },

  setBirthdayMonth(state, val) {
    state.birthdayMonth = val;
  },

  setOnSkipCallback(state, val) {
    state.onSkipCallback = val;
  },

  setView(state, val = 'signIn') {
    state.view = val;
  },

  setAccountCreatedInFlow(state, val) {
    state.accountCreatedInFlow = Boolean(val);
  },

  setTimedOut(state, val) {
    state.timedOut = val;
  },

  setOnSuccessCallback(state, val) {
    state.onSuccessCallback = val;
  },

  setCaptchaSettings(state, val = {}) {
    state.captchaSettings = val;
  },

  setSuccessfullPasswordCreation(state, val) {
    state.successfullPasswordCreation = val;
  },

  setGuestSignedIn(state, val) {
    state.guestSignedIn = val;
  }
};

function formatLoginPayload() {
  return {
    email: state.userEmail,
    password: state.userPassword,
    keepMeSignedIn: state.keepMeSignedIn,
    captchaResponse: state.captchaResponse,
    code: state.code,
  };
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};