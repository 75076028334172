import { mapActions, mapState } from "vuex";

export default {
  computed: {
    ...mapState('productCatalog', [
      'membershipInfo'
    ]),

    foundingMembershipDayPastOpening() {
      if (this?.membershipInfo?.founding?.daysPastOpening !== 0 && !this?.membershipInfo?.founding?.daysPastOpening) {
        return null;
      }
      return +this?.membershipInfo?.founding?.daysPastOpening;
    },

    foundingMembershipDiscount() {
      if (this?.membershipInfo?.founding?.discount !== 0 && !this?.membershipInfo?.founding?.discount) {
        return null;
      }

      return +this?.membershipInfo?.founding?.discount;
    },
  },

  methods: {
    ...mapActions('productCatalog', ['loadMembershipInfo']),

    foundingMembershipHasOffer(location) {
      if (!location || !location.openingDate || !this.foundingMembershipDayPastOpening) {
        return false;
      }
      let now = +new Date();
      let openingDate = +new Date(location.openingDate);

      return (now - openingDate) < (1000 * 60 * 60 * 24 * this.foundingMembershipDayPastOpening);
    },

    foundingMembershipFinalPrice(initialPrice) {
      if (this.foundingMembershipDayPastOpening !== 0 && this.foundingMembershipDayPastOpening && this.foundingMembershipDiscount !== 0 && this.foundingMembershipDiscount) {
        return initialPrice + this.foundingMembershipDiscount;
      }
      return null;
    },
  },

  mounted() {
    this.loadMembershipInfo();
  }
};
