import { constants as invoiceConstants } from '@mrModules/dataAccess/commerce/lib/invoice-constants';

const state = {
  invoiceItemTags: {
    sub: 'SUBSCRIPTION',
    sub_addon: 'SUBSCRIPTION ADD-ON'
  },
  maxQuantity: 10,
  bulkOrderPromoId: 3746,
  perfectPairInstructionId: 526, //- not used to add anymore but need to keep for removing
  perfectPairOneTimePromo: 4138,
  perfectPairSubPromoIdOne: 4139,
  invoiceConstants,
  mensProductTypes: ['mens_hair_color'],
  urmProductCode: 'hcb_memb-hcb_memb',
  omniMembProductType: 'omni_memb',
  limitless: {
    COLOR_PLUS: 'Limitless Color+',
    PREMIER: 'Limitless Premier',
    PLUS: 'Limitless Plus',
    PRO: 'Limitless Pro',
    PRO_PLUS: 'Limitless Pro+'
  },
  limitlessEvents: {
    membershipTab: {
      PRO_BOOK_SERVICE_CLICK: 'Limitless pro memberships tab - book next appointment clicked',
      PRO_MANAGE_SUB_CLICK: 'Limitless pro memberships tab - managemembership clicked'
    }
  },
  limitlessCodes: {
    COLOR_PLUS: 'color_plus_membership',
    PRO_PLUS: 'hcb_memb-hcb_memb',
    PRO: 'limitless_pro_memb',
    PLUS: 'limitless_plus'
  },
  limitlessIds: {
    PRO: 809,
    PRO_PLUS: 711
  },
  limitlessPlusProductCode: 'limitless_plus',
  limitlessPlusBulkPromoIdField: 'directMembershipBulkPromoId',
  servicePackageProductType: 'colorbar_membership',
  tenBoxOfferPromoId: 4619,
  tenBoxOfferCancelReasonSub: {
    id: 106,
    reason: 'Switch to annual subscription',
    tag: 'annual_sub',
  },
};

export default {
  namespaced: true,
  state
};