<template lang="pug">
  .site-nav-mobile-v2-main-nav.xs-12
    ul.site-nav-wrap.site-nav-mobile-main(itemscope itemtype="https://schema.org/SiteNavigationElement" role="menubar")
      li.site-nav-item(v-if="shopNav")
        button.site-nav-title.xs-f-medium.max-at-tweak(@click="openNav(shopNav.title)" role="menuitem")
          .site-nav-title-content.semi-wide-letter-spacing
            mr-icon.mr-75m(v-if="shopNav.icon" :name="shopNav.icon")
            span.bold.upper {{ shopNav.title }}
            i.icon-caret-right.ui-color-6

      li.site-nav-item(v-if="navItems" v-for="item in navItems" :key="item.title")
        button.site-nav-title.xs-f-medium.max-at-tweak(@click="openNav(item.title)" role="menuitem")
          .site-nav-title-content.semi-wide-letter-spacing
            mr-icon.mr-75m(v-if="item.icon" :name="item.icon")
            span.bold.upper {{ item.title }}
            i.icon-caret-right.ui-color-6

      li.site-nav-item(v-if="aboutNav")
        button.site-nav-title.xs-f-medium.max-at-tweak(@click="openNav(aboutNav.title)" role="menuitem")
          .site-nav-title-content.semi-wide-letter-spacing
            mr-icon.mr-75m(v-if="aboutNav.icon" :name="aboutNav.icon")
            span.bold.upper {{ aboutNav.title }}
            i.icon-caret-right.ui-color-6

      li.site-nav-item(v-if="navLinks" v-for="navLink in navLinks")
        a.site-nav-title.xs-f-medium.max-at-tweak(v-if="validNavLink(navLink)" :href="navLink.link.href" @click="mix_trackFTVNavClick('Main', navLink.link.text)" target="_self" role="menuitem")
          .site-nav-title-content.semi-wide-letter-spacing
            mr-icon.mr-75m(v-if="navLink.icon" :name="navLink.icon")
            span.bold.upper {{ navLink.link.text }}

      li.xs-pt-75m.xs-pb-75m.ui-color-5
        hr

      li.site-nav-item(role="none")
        button.site-nav-title.xs-f-medium.max-at-tweak.cta-color-1(v-if="!hideLogin" @click="openLogInModal" data-mr-ass="log-in-mobile" role="menuitem")
          span.site-nav-title-content.semi-wide-letter-spacing
            mr-icon.mr-75m(name="account")
            span.underline.bold.upper Log In

        button.site-nav-title.xs-f-medium.max-at-tweak.cta-color-1(v-else @click="openAccountModal" data-mr-ass="log-in-mobile" role="menuitem")
          span.site-nav-title-content.semi-wide-letter-spacing
            span.underline.bold.upper My Account

      li.site-nav-item(role="none")
        .site-nav-title.xs-f-medium.max-at-tweak
          span.site-nav-title-content.xs-f-medium.max-at-tweak.cta-color-1
            span#extole_zone_global_header

</template>

<script>
  import { mapActions, mapState } from 'vuex';
  import { extoleHeaderTag } from "@services/extoleSvc";
  import SiteNavTrackMixin from "@components/SiteNav/SiteNavTrackMixin/SiteNavTrackMixin";

  export default {
    name: "SiteNavMobileV2MainNav",

    mixins: [SiteNavTrackMixin],

    props: {
      value: {
        type: String,
      },
      shopNav: {
        type: Object,
      },
      navItems: {
        type: Array,
      },
      navLinks: {
        type: Array,
      },
      aboutNav: {
        type: Object,
      },
    },

    computed: {
      ...mapState('global', [
        'hideLogin',
      ]),
    },

    methods: {
      ...mapActions('modal', [
        'showModal'
      ]),
      ...mapActions('siteNav', [
        'toggleMobileSiteNav',
        'toggleAccountNav'
      ]),

      openAccountModal() {
        this.toggleMobileSiteNav();
        this.toggleAccountNav();
      },

      openNav(title) {
        this.mix_trackFTVNavViewed(title);
        this.$emit('input', title);
      },

      openLogInModal() {
        let payload = {
          component: 'MrSignInV2Modal',
          theme: 'reset-width reset-padding close-left',
        };

        this.showModal(payload);
      },

      validNavLink(navLink) {
        return navLink?.link?.href?.length > 0 && navLink?.link?.text?.length > 0;
      },
    },

    mounted() {
      extoleHeaderTag();
    }
  };
</script>

<style scoped lang="stylus">
  .site-nav-mobile-v2-main-nav
    padding-top 0.75em

    .my-account-link
      color cta-color-1!important

    .site-nav-item
    .site-nav-title
      width 100%
      display block
      text-align left

    .site-nav-title
      background-color transparent
      border none
      padding 0
      width 100%
      display block
      text-align left

      &:focus-visible
        outline -webkit-focus-ring-color auto 5px

      .site-nav-title-content
        display block
        padding 0.75em 5%

        &:focus
          outline none

        .icon-caret-right
          float right

    hr
      border-top 1px solid ui-color-5

    a
      display block
      color text-color-1

    a
    button
      background-color transparent
      border none
      padding 0

    #extole_zone_global_header
      display inline-block
      a
        color cta-color-1
        text-decoration underline
        font-family f-primary

</style>
