import { getUrmProduct } from '@services/vueUrmSvc';
const state = {
  urmProduct: {}
};

const actions = {
  getUrmProduct({ commit }) {
    getUrmProduct().then(res => {
      commit('setUrmProduct', res.data);
    });
  },
};
const mutations = {
  setUrmProduct(state, val) {
    return state.urmProduct = val;
  }
};

export default {
  namespaced: true,
  state,
  actions,
  mutations
};