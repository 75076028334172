import vueCartSvc from "@services/vueCartSvc";
import vueDoorDashSvc from "@services/vueDoorDashSvc";
import { deepCopyObj, getObjProperty } from "@utilities/mrVueUtils";

const state = {
  cic: 0,
  cart: {
    addons: [],
    items: [],
    locationId: null
  },
  cartType: 'active', //- loadCart method will load cart with this type, set it before calling loadCart
  loadingCart: false,
  cartLoaded: false,
  loadingUseCredits: false,
  cartUpdated: 0,
  addingProduct: null,
  updatingProduct: false,
  removingProduct: false,
  cartItemChangeIndex: null,
  editAllItems: false,
  availableLocations: [],
  bulkOrderRccQty: 3,
  checkingDoorDash: false,
  doorDashDeliveryInstructions: null,
  doorDashTipData: {},
  doorDashOtherTip: null,
  doorDashLoaded: false,
  doorDashError: null,
  doorDashAfterpayError: null,
  doorDashDeliveryWindows: [],
  doorDashDeliveryWindowSelected: null,
  updatingShippingMethod: false,
  doorDashTipTouched: false,
  doorDashDeliveryCode: 'DOORDASH_DELIVERY-DOORDASH_DELIVERY',
};

export const getters = {
  subscriptions(state) {
    const items = state.cart.items || [];
    return items.filter(item => item.type === 'subscription');
  },

  shippingRequired(state) {
    return getObjProperty(state.cart, 'requiresShipping');
  },

  cartContainsShipableItems(state) {
    let cart = state.cart;
    if (!cart.items || !cart.items.length) {
      return false;
    }

    const shipableItem = cart.items.find(i => i.ignore_fulfillment == 0);

    return !!shipableItem;
  },

  subscriptionsHaveDifferentFrequency(_, { subscriptions }) {
    return subscriptions.some(item => item.subscription !== subscriptions[0].subscription);
  },

  qtyByProductType: (state) => (productType) => {
    let totalQty = 0;
    let cart = state.cart;
    if (!cart.items || !cart.items.length) {
      return totalQty;
    }

    cart.items.forEach(item => {
      if (item.product_type == productType) {
        totalQty += item.qty;
      }
    });

    return totalQty;
  },

  suggestedAddons(state) {
    let addons = state.cart.addons || [];
    let items = state.cart.items || [];

    addons = addons.filter(addon => addon.xsellLocations && addon.xsellLocations.pdpModal && !items.some(item => item.id == addon.id));

    return addons.sort((a, b) => a.xsell_rank - b.xsell_rank);
  },

  cartAddons(state) {
    let addons = state.cart.addons || [];
    return addons.filter(addon => addon.xsellLocations && addon.xsellLocations.cart);
  },

  productIdInCart: (state) => (id) => {
    return state.cart.items.some(item => item.id == id);
  },

  productMaxQtyInCart: (state, getters, rootState) => (id) => {
    return state.cart.items.some(item => item.id == id ? item.qty >= rootState.constants.maxQuantity : false);
  },

  tenBoxOfferPresent(state, getters, rootState) {
    return state.cart?.totals?.appliedPromos?.find(({ id: promoId }) => promoId === rootState.constants.tenBoxOfferPromoId);
  },

  subscribableItems(state) {
    return new Set(state.cart.items.filter((item) => item.subscription == 0 && !!item.product_type_sub_frequencies && !item.isExtraTube));
  },

  freeItemsMap(state) {
    return getObjProperty(state.cart, 'totals.freeItemsMap');
  },

  numMemberships(state) {
    let count = 0;
    (state.cart.items || []).forEach(item => {
      if (item.subscription > 0) {
        count++;
      }
    });

    return count;
  },

  // @precondition state.cartUpdated > 0
  appliedPromoByType: (state) => (type) => {
    if (!state.cartInitialized) {
      throw new Error('cart has not been loaded yet.');
    }

    let isApplied = false;
    let i = 0;
    while (i < state.cart.totals.appliedPromos.length && !isApplied) {
      for (let offer of state.cart.totals.appliedPromos[i].offers) {
        if (offer.type === type) {
          isApplied = true;
          break;
        }
      }
      i++;
    }
    return isApplied;
  },

  subWithSubAttachments(state) {
    return state.cart.items.find(item => item.subscription && Object.prototype.hasOwnProperty.call(item, 'subscriptionAttachments')) || {};
  },

  cartContainsOnlyMensProducts: (state, getters, rootState) => {
    let onlyMensProducts = false;
    let ignoreProdTypes = ['accessory'];
    //- collect all product types in cart
    let allProductTypesInCart = [];

    state.cart.items.forEach(item => {
      //- ignore  product types from ignoreProdTypes and ignore free products
      if (item.product_type && !ignoreProdTypes.includes(item.product_type) && !getters.freeItemsMap[item.code]) {
        allProductTypesInCart.push(item.product_type);
      }
    });

    //- only if cart has product types we check if there are more than mens product types
    if (allProductTypesInCart.length) {
      //- filter out all the mens products and if there is anyting left that means those are not mens products.
      onlyMensProducts = !allProductTypesInCart.filter(productType => !rootState.constants.mensProductTypes.includes(productType)).length;
    }

    return onlyMensProducts;
  },

  locationId: (state) => {
    return state.cart.locationId;
  },

  doorDashDeliverySelected(state) {
    const selectedShipMethod = getObjProperty(state, 'cart.shippingMethods.selected') || {};
    return !state.updatingShippingMethod && selectedShipMethod.code === state.doorDashDeliveryCode;
  },

  allItemsDoorDashEligible(state) {
    return !state.cart.items.some(item => !item.doorDashEligible);
  },

  doorDashItemEligibilityStatus(state) {
    let eligibility = null;
    let totalItemCount = state.cart.items.length;
    let ddEligibleItemCount = state.cart.items.filter(item => item.doorDashEligible).length;

    if (ddEligibleItemCount > 0) {
      if (totalItemCount === ddEligibleItemCount) {
        eligibility = 'full';
      } else {
        eligibility = 'partial';
      }
    }

    return eligibility;
  },

  cartContainsUpgrade(state, getters, rootState, rootGetters) {
    return getters.cartContainsLimitlessProPlus && rootGetters['customer/limitlessPro'];
  },

  cartContainsUrm(state) {
    let cartItems = getObjProperty(state, 'cart.items') || [];
    return cartItems.find(item => item.isURM || item.isHCBMembership);
  },

  cartContainsLimitlessPro(state, getters, rootState) {
    let cartItems = getObjProperty(state, 'cart.items') || [];
    return cartItems.find(item => item.code == rootState.constants.limitlessCodes.PRO);
  },

  cartContainsLimitlessProPlus(state, getters, rootState) {
    let cartItems = getObjProperty(state, 'cart.items') || [];
    return cartItems.find(item => item.code == rootState.constants.limitlessCodes.PRO_PLUS);
  },

  cartContainsLimitlessPlus(state) {
    let cartItems = getObjProperty(state, 'cart.items') || [];
    return cartItems.find(item => item.isLimitlessPlus);
  },

  cartContainsColorPlus(state) {
    let cartItems = getObjProperty(state, 'cart.items') || [];
    return cartItems.find(item => item.product_type == 'color_plus_membership');
  },

  cartContainsOnlyLimitlessPlus(state, getters) {
    return getters.cartContainsLimitlessPlus && getObjProperty(state, 'cart.items').length === 1;
  },

  cartContainsRcc(state) {
    let rccSub = [];
    let cart = state.cart;
    if (!cart.items || !cart.items.length) {
      return;
    }

    cart.items.forEach(item => {
      if (item.product_type == 'color_kit') {
        rccSub.push(item);
      }
    });

    return Boolean(rccSub.length);
  },

  cartContainsRccOneTime(state) {
    let rccSub = [];
    let cart = state.cart;
    if (!cart.items || !cart.items.length) {
      return;
    }

    cart.items.forEach(item => {
      if (item.product_type == 'color_kit' && item.type == "one-time") {
        rccSub.push(item);
      }
    });

    return Boolean(rccSub.length);
  },

  cartContainsRccSubscription(state) {
    let rccSub = [];
    let cart = state.cart;
    if (!cart.items || !cart.items.length) {
      return;
    }

    cart.items.forEach(item => {
      if (item.product_type == 'color_kit' && item.type == "subscription") {
        rccSub.push(item);
      }
    });

    return Boolean(rccSub.length);
  },
};

export const actions = {
  initCart({ state, dispatch }) {
    if (state.loadingCart || state.cartLoaded) {
      return;
    }

    dispatch('loadCart');
  },

  loadCart({ state, commit, dispatch, getters }) {
    commit('setLoadingCart', true);
    //- Clear DoorDash Error if exists
    if (state.doorDashError) {
      commit('setDoorDashError', null);
    }

    return new Promise((resolve) => {
      vueCartSvc.getCartDataForView({
        cartType: state.cartType,
        noCookieSave: true,
        keys: ['all', 'includeStrikePrice']
      }).then(res => {
        let cart = res.data;
        // boost rank of Professional Color Tool Kit for pdp
        (cart.addons || []).forEach(addon => {
          if (addon.id == 145) {
            addon.xsell_rank = 100;
          }
        });

        commit('setCart', cart);

        //- when not all items are DoorDash eligible and DoorDash delivery is selected, reset the shipping method
        if (!getters.allItemsDoorDashEligible && getters.doorDashDeliverySelected) {
          dispatch('setShippingMethod', { code: 'SHIP_DEFAULT' });
        }

        commit('setCartLoaded', true);
        //- reset cart item update progress
        commit('setCartItemChangeIndex', null);

        resolve(cart);
      }).finally(() => {
        if (state.updatingShippingMethod) {
          commit('setUpdatingShippingMethod', false);
        }
        commit('setLoadingCart', false);
      });
    });
  },

  addMembershipToCart({ dispatch, state, rootState, getters, rootGetters }, params) {
    if (state.addingProduct) {
      return;
    }

    let loadedCart = Promise.resolve();

    if ((params.productId === rootState.constants.limitlessIds.PRO || params.productId === rootState.constants.limitlessIds.PRO_PLUS) && !state.cartLoaded) {
      loadedCart = dispatch('loadCart');
    }

    return loadedCart.then(() => {
      let err = null;
      if (params.productId === rootState.constants.limitlessIds.PRO && (getters.cartContainsLimitlessProPlus || rootGetters["customer/limitlessPro"])) {
        if (getters.cartContainsLimitlessProPlus) {
          err = 'You already have a Limitless Pro+ membership in your cart. To join Limitless Pro instead, please remove Limitless Pro+ from your cart.';
        } else if (rootGetters["customer/limitlessPro"]) {
          err = 'You are already a Limitless Pro member';
        }
        dispatch('notifyError', err, { root: true });
        return Promise.reject(err);
      }
      if (params.productId === rootState.constants.limitlessIds.PRO_PLUS && (getters.cartContainsLimitlessPro || rootGetters["customer/limitlessProPlus"])) {
        if (getters.cartContainsLimitlessPro) {
          err = 'You already have a Limitless Pro membership in your cart. To join Limitless Pro+ instead, please remove Limitless Pro from your cart.';
        } else if (rootGetters["customer/limitlessProPlus"]) {
          err = 'You are already a Limitless Pro+ member';
        }
        dispatch('notifyError', err, { root: true });
        return Promise.reject(err);
      }
      return dispatch('addToCart', params);
    });

  },

  addToCart({ commit, dispatch, state, rootState }, params) {
    if (state.addingProduct) {
      return;
    }

    return new Promise((resolve, reject) => {
      commit('setAddingProduct', params.productId);
      let timeout = 0;

      var payload = {
        product_id: params.productId,
        qty: params.quantity || params.qty || 1,
        subscription: params.subscription || 0
      };

      if (params.giftCard) {
        payload.giftCard = params.giftCard;
      }

      //- DO NOT use this property, left here for any loose ends. For actions on refillCart use refillCart store
      if (params.cartType) {
        payload.cartType = params.cartType;
      }

      if (params.ctaSource) {
        payload.ctaSource = params.ctaSource;
      }

      if (params.validOffer) {
        payload.validOffer = params.validOffer;
      }

      if (params.membershipAddon) {
        payload.membershipAddon = params.membershipAddon;
      }

      if (params.attachableProdIds) {
        payload.attachableProdIds = params.attachableProdIds;
      }

      if (params.extra) {
        payload.addExtraTube = params.extra;
      }

      if (params.locationId) {
        payload.locationId = params.locationId;
      }

      // pass timeout to delay product being removed from section/carousel
      if (params.timeout) {
        timeout = params.timeout;
      }

      vueCartSvc.addToCart(payload).then(() => {
        if (rootState.colorbar.showBopis && !rootState.colorbar.initializingBopis) {
          dispatch('colorbar/closestAvailableLocations', {}, { root: true });
        }
        setTimeout(() => {
          dispatch('loadCart').then(() => {
            commit('setCartUpdated');
            dispatch('handleDoorDashOnCartUpdate');
            commit('setAddingProduct', null);
            resolve();
          });
        }, timeout);
      }).catch((err) => {
        if (params.notifyError) {
          dispatch('notifyError', err, { root: true });
        }
        commit('setAddingProduct', null);
        reject(err);
      });
    });
  },

  addCustomGiftCardToCart({ commit, dispatch, state, rootState }, params) {
    if (state.addingProduct) {
      return;
    }

    commit('setAddingProduct', true);

    return new Promise((resolve, reject) => {
      var payload = {
        product_id: params.productId,
        qty: params.quantity || params.qty || 1,
        subscription: params.subscription || 0,
        unit_price: params.unitPrice
      };

      if (params.giftCard) {
        payload.giftCard = params.giftCard;
      }

      //- DO NOT use this property, left here for any loose ends. For actions on refillCart use refillCart store
      if (params.cartType) {
        payload.cartType = params.cartType;
      }

      if (params.ctaSource) {
        payload.ctaSource = params.ctaSource;
      }

      if (params.validOffer) {
        payload.validOffer = params.validOffer;
      }

      if (params.membershipAddon) {
        payload.membershipAddon = params.membershipAddon;
      }

      if (params.attachableProdIds) {
        payload.attachableProdIds = params.attachableProdIds;
      }

      if (params.extra) {
        payload.addExtraTube = params.extra;
      }

      if (params.locationId) {
        payload.locationId = params.locationId;
      }

      vueCartSvc.addCustomGiftCardToCart(payload).then(() => {
        if (rootState.colorbar.showBopis && !rootState.colorbar.initializingBopis) {
          dispatch('colorbar/closestAvailableLocations', {}, { root: true });
        }
        dispatch('loadCart').then(() => {
          commit('setCartUpdated');
          commit('setAddingProduct', false);
          resolve();
        });
      }).catch((err) => {
        dispatch('notifyError', err, { root: true });
        commit('setAddingProduct', false);
        reject(err);
      });
    });
  },

  async addMultipleItemsToCart({ commit, dispatch, state, rootState }, params) {
    if (state.addingProduct) {
      return;
    }

    commit('setAddingProduct', true);

    const asyncFunctions = [];

    if (params.items) {
      let bulkItems = params.items.filter(item => item.bulkOrderRCC);

      bulkItems.forEach(item => item.qty = state.bulkOrderRccQty);

      if (bulkItems.length) {
        // I should update the boosts quantities if they haven't been updated previously
        state.cart.items
          .filter(item => item['product_type'] === 'boosts' && item.qty < state.bulkOrderRccQty)
          .map(item =>
            asyncFunctions.push(async () => await dispatch('updateItemQty', {
              index: item.index,
              qty: state.bulkOrderRccQty,
              ctaSource: params.ctaSource
            })));
      }
    }

    try {
      asyncFunctions.push(async () => {
        await vueCartSvc.addMultipleItemsToCart(params);
        await dispatch('loadCart');
        if (rootState.colorbar.showBopis && !rootState.colorbar.initializingBopis) {
          await dispatch('colorbar/closestAvailableLocations', {}, { root: true });
        }
        commit('setCartUpdated');
        dispatch('handleDoorDashOnCartUpdate');
      });


      await asyncFunctions.reduce(async (previousPromise, nextFunction) => {
        await previousPromise;
        await nextFunction();
      }, Promise.resolve());
    } finally {
      commit('setAddingProduct', false);
    }
  },

  updateItemQty({ commit, dispatch, state, rootState }, params) {
    if (state.updatingProduct) {
      return;
    }
    if (params.qty < 1 || params.qty > 10) {
      return;
    }
    commit('setUpdatingProduct', true);
    commit('setCartItemChangeIndex', params.index);

    var payload = {
      index: params.index,
      qty: params.qty,
      ctaSource: params.ctaSource
    };

    return vueCartSvc.updateItemQty(payload).then(() => {
      dispatch('loadCart');
      if (rootState.colorbar.showBopis && !rootState.colorbar.initializingBopis) {
        dispatch('colorbar/closestAvailableLocations', {}, { root: true });
      }
      commit('setCartUpdated');
      commit('setUpdatingProduct', false);
      dispatch('handleDoorDashOnCartUpdate');
    }).catch(() => {
      commit('setUpdatingProduct', false);
      dispatch('notifyError', null, { root: true });
    });
  },

  updateItemSubscription({ commit, dispatch, state, rootState }, params) {
    if (state.updatingProduct) {
      return;
    }
    commit('setUpdatingProduct', true);
    commit('setCartItemChangeIndex', params.index);

    return new Promise((resolve, reject) => {
      var payload = {
        index: params.index,
        subscription: params.subscription,
        ctaSource: params.ctaSource
      };

      //- DO NOT use this property, left here for any loose ends. For actions on refillCart use refillCart store
      if (params.cartType) {
        payload.cartType = params.cartType;
      }

      vueCartSvc.updateItemSubscription(payload).then(() => {
        commit('setUpdatingProduct', false);
        dispatch('loadCart');
        if (rootState.colorbar.showBopis && !rootState.colorbar.initializingBopis) {
          dispatch('colorbar/closestAvailableLocations', {}, { root: true });
        }
        dispatch('handleDoorDashOnCartUpdate');
        resolve();
      }).catch(() => {
        commit('setUpdatingProduct', false);
        dispatch('notifyError', null, { root: true });
        reject();
      });
    });
  },

  removeFromCart({ commit, dispatch, state, rootState }, params) {
    if (state.removingProduct) {
      return;
    }

    commit('setRemovingProduct', true);
    commit('setCartItemChangeIndex', params.index);

    var payload = {
      index: params.index,
      ctaSource: params.ctaSource
    };

    //- DO NOT use this property, left here for any loose ends. For actions on refillCart use refillCart store
    if (params.cartType) {
      payload.cartType = params.cartType;
    }

    dispatch('handleDoorDashOnCartUpdate');

    const shouldGetclosestAvailableLocations = state.cart.count !== 1;
    return vueCartSvc.removeFromCart(payload)
      .then(() => {
        commit('setRemovingProduct', false);
        dispatch('loadCart');
        if (shouldGetclosestAvailableLocations && rootState.colorbar.showBopis && !rootState.colorbar.initializingBopis) {
          dispatch('colorbar/closestAvailableLocations', {}, { root: true });
        }
        commit('setCartUpdated');
      })
      .catch(() => {
        commit('setRemovingProduct', false);
        dispatch('notifyError', null, { root: true });
        commit('setCartItemChangeIndex', null);
      });
  },

  setShippingMethod({ dispatch, commit, state }, params) {
    if (params.code == state.doorDashDeliveryCode && state.doorDashDeliveryCode) {
      commit('setDoorDashTipTouched', false);
    }

    commit('setUpdatingShippingMethod', true);

    return vueCartSvc.setShippingMethod(params).then(() => {
      //- its necessary to get the full cart reload from getCartDataForView since
      //- that has the most accurate data for the front end
      dispatch('loadCart');
      commit('setUpdatingShippingMethod', false);
    }).catch((err) => {
      //- notify customer
      dispatch('notifyError', null, { root: true });
      //- also send the the err to Sentry
      dispatch('notifyError', { silent: true, err }, { root: true });
      commit('setUpdatingShippingMethod', false);
    });
  },

  setShippingAddress({ dispatch }, params) {
    let address = params.address;
    return vueCartSvc.setShippingAddress({ address }).then(() => {
      dispatch('loadCart');
    });
  },

  applyCoupon({ commit, dispatch, rootState }, params) {
    return new Promise((resolve, reject) => {
      vueCartSvc.applyCoupon(params).then(() => {
        dispatch('loadCart');
        if (rootState.colorbar && rootState.colorbar.showBopis && !rootState.colorbar.initializingBopis) {
          dispatch('colorbar/closestAvailableLocations', {}, { root: true });
        }
        commit('setCartUpdated');
        resolve();
      }).catch(err => {
        reject(err.response.data);
      });
    });
  },

  openRemovePromoModal({ dispatch }, params) {
    dispatch('modal/showModal', {
      component: 'CartRemovePromoModal',
      props: {
        promoId: params.promoId
      }
    }, { root: true });
  },

  removeCoupon({ commit, dispatch, rootState }, params) {
    return new Promise((resolve, reject) => {
      vueCartSvc.removeCoupon(params).then(() => {
        dispatch('loadCart');
        if (rootState.colorbar.showBopis && !rootState.colorbar.initializingBopis) {
          dispatch('colorbar/closestAvailableLocations', {}, { root: true });
        }
        commit('setCartUpdated');
        resolve();
      }).catch(err => {
        reject(err.response.data);
      });
    });
  },

  moveToOtherCart({ dispatch }, params) {
    vueCartSvc.moveToOtherCart(params).then(() => {
      //- refresh both carts
      dispatch('loadCart');
      dispatch('refillCart/loadRefillCart', null, { root: true });
    });
  },

  getAvailabilityByRegion({ commit }, params) {
    return new Promise((resolve, reject) => {
      vueCartSvc.getAvailabilityByRegion(params)
        .then(res => {
          if (!params.noPersist) {
            commit('setAvailableLocations', res.data);
          }
          resolve(res.data);
        })
        .catch(reject);
    });
  },
  updateCartFromCookies({ commit }) {
    commit('setCartUpdated');
  },

  setLocationId({ commit, dispatch }, params) {
    return new Promise((resolve, reject) => {
      vueCartSvc.setLocationId(params).then(() => {
        commit('updateLocationId', params.locationId);
        dispatch('loadCart');
        resolve();
      }).catch(err => {
        dispatch('notifyError', null, { root: true });
        reject(err.response.data);
      });
    });
  },

  async setSameDayDelivery({ state, commit, dispatch }) {
    try {
      commit('setUpdatingShippingMethod', true);
      await vueCartSvc.setToSameDayDelivery({ code: state.doorDashDeliveryCode });
      await dispatch('loadCart');
    } catch (err) {
      commit('setUpdatingShippingMethod', false);
      dispatch('notifyError', { silent: true, err }, { root: true });
      dispatch('notifyError', 'Something went wrong and we could not add same day delivery.', { root: true });
    }
  },

  async setToShipToHome({ commit, dispatch }) {
    try {
      commit('setUpdatingShippingMethod', true);
      await vueCartSvc.setToShipToHome();
      await dispatch('loadCart');
    } catch (err) {
      commit('setUpdatingShippingMethod', false);
      dispatch('notifyError', { silent: true, err }, { root: true });
      dispatch('notifyError', 'Something went wrong and we could not enable ship to home.', { root: true });
    }
  },

  updateAvailableLocations({ commit }, params) {
    commit('setAvailableLocations', params ? params : []);
  },

  async setDoorDashTip({ commit }, params) {
    const res = await vueCartSvc.setDoorDashTip(params);

    let cartCopy = deepCopyObj(state.cart);
    let { totals } = res.data;

    cartCopy.totals = totals;
    commit('setCart', cartCopy);
    commit('setDoorDashTipTouched', true);
  },

  getAvailableDoorDashDeliveryTimes({ state, commit, dispatch }, params) {
    commit('setDoorDashError', null);
    commit('setCheckingDoorDash', true);

    let payload = {
      deliveryAddress: params.address
    };

    return new Promise((resolve, reject) => {
      vueDoorDashSvc.getAvailableDoorDashDeliveryTimes(payload).then(res => {
        let timeWindows = getObjProperty(res, 'data.deliveryWindows');
        const doorDashLocationId = getObjProperty(res, 'data.doorDashLocationId');

        commit('setDoorDashDeliveryWindows', timeWindows || []);

        if (!timeWindows || !doorDashLocationId) {
          let noDDErr = 'Door Dash is not available at this time';
          commit('setDoorDashError', noDDErr);
          dispatch('notifyError', noDDErr, { root: true });
          return reject();
        }

        vueCartSvc.setDoorDashLocationId({ doorDashLocationId }).then(() => {
          //- select first by default
          if (state.doorDashDeliveryWindows.length) {
            if (!state.doorDashDeliveryWindowSelected) {
              commit('setDoorDashDeliveryWindowSelected', state.doorDashDeliveryWindows[0]);
            } else {
              //- check if current selected still exists, if not re-select
              let alreadySelected = state.doorDashDeliveryWindows.find(window => window.displayLabel == state.doorDashDeliveryWindowSelected.displayLabel);

              if (!alreadySelected) {
                commit('setDoorDashDeliveryWindowSelected', state.doorDashDeliveryWindows[0]);
              }
            }
          }

          commit('setCheckingDoorDash', false);
          commit('setDoorDashLoaded', true);

          resolve();
        }).catch(() => {
          dispatch('notifyError', null, { root: true });
          reject();
        });

      }).catch(err => {
        commit('setCheckingDoorDash', false);
        let errMsg = getObjProperty(err, 'response.data.message') || 'Something went wrong and your action can not be completed';
        if (errMsg) {
          commit('setDoorDashError', errMsg);
        }

        let errPayload = errMsg ? errMsg : err;
        dispatch('notifyError', errPayload, { root: true });
        reject();
      });
    });
  },

  handleDoorDashOnCartUpdate({ getters, dispatch, rootGetters }) {
    //- when doordash delivery and shipping is selected we need to re-evaluate DD-ability
    const shipAddress = rootGetters['addressBook/selectedAddress'];
    if (getters.doorDashDeliverySelected && shipAddress) {
      let payload = {
        address: shipAddress
      };

      dispatch('getAvailableDoorDashDeliveryTimes', payload);
    }
  },

  async toggleUseCreditsOnCart({ commit, dispatch }, params) {
    commit('setLoadingUseCredits', true);
    try {
      await vueCartSvc.setUseCredits(params);
      await dispatch('loadCart');
      commit('setLoadingUseCredits', false);
    } catch (e) {
      dispatch('notifyError', e, { root: true });
    }
  }
};

export const mutations = {
  setCart(state, val) {
    state.cart = val;
    if (!state.cartInitialized) {
      state.cartInitialized = true;
    }
  },

  setLoadingCart(state, val) {
    state.loadingCart = val;
  },

  setLoadingUseCredits(state, val) {
    state.loadingUseCredits = val;
  },

  setCartLoaded(state, val) {
    state.cartLoaded = Boolean(val);
  },

  setCartUpdated(state) {
    state.cartUpdated += 1;
    state.cic = this._vm.$cookies.get('cic') || 0;
  },

  setAddingProduct(state, val) {
    state.addingProduct = val;
  },

  setUpdatingProduct(state, val) {
    state.updatingProduct = val;
  },

  setRemovingProduct(state, val) {
    state.removingProduct = val;
  },

  setCartItemChangeIndex(state, val) {
    state.cartItemChangeIndex = val;
  },

  setEditAllItems(state, val) {
    state.editAllItems = Boolean(val);
  },

  setCartType(state, val = 'active') {
    state.cartType = val;
  },

  setAvailableLocations(state, val = []) {
    state.availableLocations = val;
  },

  updateLocationId(state, val) {
    state.cart.locationId = val;
  },

  setDoorDashDeliveryInstructions(state, val) {
    state.doorDashDeliveryInstructions = val;
  },

  setDoorDashTipData(state, val) {
    state.doorDashTipData = val;
  },

  setDoorDashDeliveryWindows(state, val) {
    state.doorDashDeliveryWindows = val;
  },

  setDoorDashDeliveryWindowSelected(state, val) {
    state.doorDashDeliveryWindowSelected = val;
  },

  setDoorDashLoaded(state, val) {
    state.doorDashLoaded = Boolean(val);
  },

  setCheckingDoorDash(state, val) {
    state.checkingDoorDash = Boolean(val);
  },

  setDoorDashError(state, val) {
    state.doorDashError = val;
  },

  setDoorDashAfterpayError(state, val) {
    state.doorDashAfterpayError = val;
  },

  setUpdatingShippingMethod(state, val) {
    state.updatingShippingMethod = Boolean(val);
  },

  setDoorDashTipTouched(state, val) {
    state.doorDashTipTouched = Boolean(val);
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};