<template lang="pug">
  .container.content-site-messages(v-if="hasMessages")
    .carousel-wrapper
      .the-carousel
        simple-carousel(:options="options")
          template(v-slot:left-nav-content="")
            span(v-show="messages.length > 1" tabindex="-1").icon-caret-left.color-white.xs-f-xsmall.max-at-tweak.non-focusable
              span.ada-tooltip previous

          .topic-item.text-center(v-for='topic in sortedMessages')
            .topic.max-at-tweak.v-center.xs-f-xxxsmall(v-html="topic.message")

          template(v-slot:right-nav-content="")
            span(v-show="messages.length > 1" tabindex="-1").icon-caret-right.color-white.xs-f-xsmall.max-at-tweak.non-focusable
              span.ada-tooltip next

</template>

<script>
  import { mapActions } from 'vuex';
  import vueSiteMessageSvc from '@services/vueSiteMessageSvc';
  import SimpleCarousel from './SimpleCarousel';

  export default {
    name: "ContentSiteMessages",
    components: {SimpleCarousel},

    props: {
      topics: {
        type: Array,
        default() {
          return [];
        }
      }
    },

    data: () => ({
      messages: [],
      options: {
        slidesToShow: 1,
        slidesToScroll: 1,
        focusOnSelect: false,
        infinite: true,
        arrows: true,
        autoplay: false
      },
    }),
    computed: {
      curatedTopics() {
        return this.topics.filter(v => v).map(t => t.trim().toLowerCase()).join(',');
      },

      hasMessages() {
        return !!(Array.isArray(this.messages) && this.messages.length);
      },

      sortedMessages() {
        let messages = [];

        if (this.hasMessages) {
          let importanceMap = {
            high: 1,
            medium: 2,
            low: 3,
          };

          messages = [...this.messages].sort((a, b) => {
            if (a.importance != b.importance) {
              return importanceMap[a.importance] - importanceMap[b.importance];
            }

            let aStart = new Date(a.start_date);
            let bStart = new Date(b.start_date);

            return +bStart - +aStart;
          });
        }

        return messages;
      }
    },
    watch: {
      topics: {
        immediate: true,
        handler() {
          if (this.curatedTopics.length) {
            vueSiteMessageSvc.getByTopics({ topics: this.curatedTopics }).then(res => {
              this.messages = res.data;
            });
          }
        }
      }
    },

    methods: {
      ...mapActions([
        'notifyError'
      ]),
    },
  };
</script>

<style scoped lang="stylus">
.topic-item
  font-size 12px
  width 100%
  transform translate(0%, 50%)
  .topic
    color white !important
    width 100%
    margin-top -16px
    a
      color white
      font-weight bold

    .holidaystars-image
      display none
      opacity 0
      transition(opacity 1500ms ease-in-out)

    .holiday-stars-image
      &.holidaystars
        width 100%
        height 20em
        opacity 100

.content-site-messages
  position relative
  background-color #53284f
  width 100%
  height 32px
  margin-top -1px
  overflow  hidden
  .carousel-wrapper
    transform translate(50%, 2px)

.simple-carousel
  height 32px
  margin-top -0.3%

@media only screen and (max-width: 440px)
  .the-carousel
    width 100%
    margin-left 0px

@media mq-mobile
  .content-site-messages
    height 43px

  .simple-carousel
    height 43px
    margin-top -0.6% !important

  .the-carousel
    width 100%

  .topic-item
    height 43px

  .topic
    margin-top -21.5px
@media mq-mobile-plus
  .content-site-messages
    height 51px
    .topic-item
      height 51px
      .topic
        margin-top -25px

  .simple-carousel
    height 51px
    margin-top -0.5%

  .the-carousel
    width 96%
    margin-left -48%

@media mq-tablet-plus
  .the-carousel
    width 86%
    margin-left -43%

@media mq-desktop-md
  .the-carousel
    width 86%
    margin-left -43%

  .simple-carousel
    height 51px
    margin-top -0.4%

@media mq-desktop
  .the-carousel
    width 71%
    margin-left -35.5%

@media mq-max
  .the-carousel
    width 56.5%
    margin-left -28.25%

  .simple-carousel
    height 51px

@media only screen and (min-width: 1440px)
  .the-carousel
    width 815.8px
    margin-left -407.9px !important
  .simple-carousel
    height 51px
    margin-top -0.4%
</style>