<template lang="pug">
  .site-nav-mobile-v2-sub-nav.xs-12
    .header.xs-12
      button.back-button.btn-reset.xs-f-medium.lg-f-small.max-at-tweak(type="button" @click="$emit('input', null)")
        i.icon-caret-left.cta-color-1
        span.ada-tooltip back

      .nav-title.bold.upper.xs-f-medium.lg-f-small.max-at-tweak.text-color-1 {{ aboutNav.title }}

    .nav-content.xs-12
      .sub-nav.text-left(v-for="subNav in subNavs" :key="subNav.title")
        .nav-sub-header.upper.xs-f-small.lg-f-xsmall.max-at-tweak.text-color-2(v-if="subNavs.length > 1") {{ subNav.title }}
        ul.xs-f-medium.lg-f-small.max-at-tweak(role="menu")
          li.sub-nav-item.xs-f-medium.lg-f-small.max-at-tweak(v-for="(item) in subNav.navItems" :key="item.title + item.url" role="none")
            a.xs-f-medium.lg-f-small.max-at-tweak(:href="item.url" @click="mix_trackItemClick(item.title)" role="menuitem")
              .main-title.text-color-1.xs-f-medium.lg-f-small.max-at-tweak {{ item.title }}
              .sub-title.text-color-3.xs-f-xsmall.max-at-tweak(v-if="item.subTitle") {{ item.subTitle }}

      .marketing-placement(v-if="marketingPlacement")
        CMSPartialSsr(:mixin-key="marketingPlacement.mixin_key")

</template>

<script>
  import SiteNavTrackMixin from "@components/SiteNav/SiteNavTrackMixin/SiteNavTrackMixin";
  import CMSPartialSsr from '@components/CmsPartialSsr';

  export default {
    name: "SiteNavMobileV2AboutNav",

    mixins: [SiteNavTrackMixin],

    components: {
      CMSPartialSsr,
    },

    props: {
      value: {
        type: String
      },
      aboutNav: {
        type: Object,
        required: true,
      }
    },

    computed: {
      subNavs() {
        return this.aboutNav?.categories ?? [];
      },

      marketingPlacement() {
        return this.aboutNav?.marketingPlacement ?? null;
      }
    }
  };
</script>

<style scoped lang="stylus">
.site-nav-mobile-v2-sub-nav
  padding-bottom 4em

  .header
    position relative

    .back-button
      padding 1em
      position absolute
      top 50%
      left 0
      margin-top -1.75em

    .nav-title
      padding 1em

  .nav-sub-header
    background-color ui-color-3
    padding 0.5em 3%
    margin 0.25em 0
    display inline-block

  .nav-content
    height 100%
    overflow-y scroll

    .marketing-placement
      padding 2em

  li.sub-nav-item
    padding 0.5em 3%

</style>
