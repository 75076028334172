import dataToolSvc from '@services/dataToolSvc';

const state = {
  imageChoices: {},
  selectedColor: 'brunette',
  selectedTexture: 'straight',
};
const getters = {
  getImageForTypeAndColor: (state) => (params) => {
    let { color, texture, style } = params;
    color = color ? color : state.selectedColor;
    texture = texture ? texture : state.selectedTexture;
    style = style ? style : 'main';
    let image = state.imageChoices[color]?.[texture]?.[style];
    if (image) {
      return image;
    } else {
      return null;
    }
  }
};

const actions = {
  async fetchImagesForTypeAndColor({ commit }) {
    const res = await dataToolSvc.getData({mixinKey: 'hair-type-and-texture-imagery'});
    commit('setImageChoices', res.data);
  }
};

const mutations = {
  setImageChoices(state, val) {
    state.imageChoices = val;
  },
  setSelectedColor(state, val) {
    state.selectedColor = val;
  },
  setSelectedTexture(state, val) {
    state.selectedTexture = val;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};