<template lang="pug">
  section.site-nav-mobile-v2-shop-nav.xs-12(role="region" aria-label="Shop Sub Navigation")
    transition(:name="slideAnimation" mode="out-in")
      .site-nav-mobile-v2-shop-nav-inner
        header.header.xs-12
          button.back-button.btn-reset.upper.f-primary.narrow-letter-spacing.xs-f-medium.lg-f-small.max-at-tweak(type="button" @click="$emit('input', null)" aria-label="Go back")
            i.icon-caret-left.cta-color-1
            span.ada-tooltip back

          h2.nav-title.f-primary.narrow-letter-spacing.bold.upper.xs-f-medium.max-at-tweak.cw-color-11 {{ shopNav.title }}

        nav.nav-content.xs-12(aria-label="Shop Categories")
          .accordion-section(v-for="(subNav, index) in shopNav.subNavs" :key="subNav.title" role="region" :aria-label="'Shop by ' + subNav.title")
            .accordion-header.text-left(@click="toggleSubNav(index)" role="button" :aria-expanded="isOpen(index).toString()" :aria-controls="'accordion-content-' + index" tabindex="0" @keydown.enter="toggleSubNav(index)" @keydown.space.prevent="toggleSubNav(index)")
              .titles
                h5.f-primary.bold.mb-25m.text-color-3.shop-by-title SHOP BY
                h4.f-primary.narrow-letter-spacing.bold.xs-f-xmedium.cw-color-11 {{ subNav.title }}

              i.icon-caret-down.cw-color-11(:class="{'rotate': isOpen(index)}")

            div.accordion-content(:class="{ open: isOpen(index) }" :id="'accordion-content-' + index" :inert="!isOpen(index)")
              ul.sub-nav-items(role="menu")
                li.sub-nav-item(v-for="(navItem) in subNav.navItems" :key="navItem.link.text" role="none")
                  a.accordion-link(:href="navItem.link.href" @click="mix_trackFTVNavClick('shop', navItem.link.text)" role="menuitem")
                    img(v-if="navItem.image && showNavImgOnMediaQuery(navItem)" :src="navItem.image.url" :alt="navItem.link.text")
                    .nav-texts.text-left
                      p.bold.xs-f-small.f-primary.narrow-letter-spacing.cw-color-11 {{ navItem.link.text }}
                      p.f-primary.xs-f-xsmall.narrow-letter-spacing.text-color-3(v-if="navItem.subText") {{ navItem.subText }}

          .ctas-wrap(role="region" aria-label="Call to Action")
            .button-cta-wrap(v-if="buttonCtas.length > 0")
              MrBtn.mb-75m.site-nav-mobile-btn-cta.xs-f-xsmall(v-for="(cta) in buttonCtas" :key="cta.text" round block no-right-padding tag="a" :href="cta.href" @click="mix_trackFTVNavClick('shop', cta.text)")
                span.cta-content {{ cta.text }}

                template(#appendContent="")
                  .arrow-container.cta-color-1
                    MrIcon(name="arrow-right")

            .image-cta-wrap(v-if="displayMarketingSlot")
              CmsPartialSsr(:mixin-key="marketingMixinKey" role="region" aria-label="Marketing Banner")

</template>

<script>
  import SiteNavTrackMixin from "@components/SiteNav/SiteNavTrackMixin/SiteNavTrackMixin";
  import MrBtn from "@components/MrBtn";
  import MrIcon from "@components/MrIcon";
  import { mapState } from "vuex";
  import CmsPartialSsr from "@components/CmsPartialSsr";

  export default {
    name: "SiteNavMobileV2ShopNav",
    components: {
      CmsPartialSsr,
      MrBtn,
      MrIcon
    },
    mixins: [SiteNavTrackMixin],
    inject: ['getObjProperty'],
    props: {
      value: {
        type: String
      },
      shopNav: {
        type: Object,
        required: true
      }
    },
    data() {
      return {
        openedIndex: 0
      };
    },
    computed: {
      ...mapState('global', ['windowData']),

      isMobileOrTablet() {
        return this.windowData.breakpoint == 'mobile' || this.windowData.breakpoint == 'tablet';
      },

      isDesktop() {
        return this.getObjProperty(this, 'windowData.breakpoint') === 'desktop' || this.getObjProperty(this, 'windowData.breakpoint') === 'desktop-md';
      },

      buttonCtas() {
        if (!this.shopNav.buttonCta) {
          return [];
        }
        if (!Array.isArray(this.shopNav.buttonCta)) {
          return [this.shopNav.buttonCta];
        }
        return this.shopNav.buttonCta;
      },

      marketingMixinKey() {
        return this.shopNav?.marketingSlot?.mixin_key;
      },

      displayMarketingSlot() {
        return this.marketingMixinKey && this.marketingMixinKey.length > 0;
      },

      slideAnimation() {
        return "fade-slide-right";
      }
    },

    methods: {
      showNavImgOnMediaQuery(navItem) {
        return navItem.imgOnDesktop && this.isDesktop || navItem.imgOnMobile && this.isMobileOrTablet;
      },
      toggleSubNav(index) {
        if (this.openedIndex === index) {
          this.openedIndex = null;
        } else {
          const subNavTitle = this.shopNav.subNavs[index].title;
          this.mix_trackFTVNavViewed(subNavTitle);
          this.openedIndex = index;
        }
      },
      isOpen(index) {
        return this.openedIndex === index;
      }
    }
  };
</script>

<style lang="stylus">
.site-nav-mobile-btn-cta
  .append-content
    margin-left auto!important
</style>

<style scoped lang="stylus">
.site-nav-mobile-v2-shop-nav
  padding-bottom 4em

  header.header
    background-color #F7F7F9
    position relative

    .back-button
      padding 1em
      position absolute
      top 50%
      left 0
      margin-top -1.75em

    .nav-title
      padding 1em

  .nav-content
    background-color #F7F7F9

  .accordion-section
    background-color #fff
    margin 0.5em 0
    border-radius 1.875em
    padding 1.25em 1.5em

    .accordion-header
      display flex
      align-items center
      justify-content space-between
      cursor pointer
      padding o 0.5em

      .shop-by-title
        font-size 11px

      i.icon-caret-down
        transition transform 0.3s ease

        &.rotate
          transform rotate(180deg)

    .accordion-content
      max-height 0
      overflow hidden
      transition max-height 0.3s ease
      padding 0 0.75em

      ul.sub-nav-items
        margin-top 1em

        .sub-nav-item
          margin-bottom 0.75em

          .accordion-link
            display flex
            align-items center
            text-decoration none

            img
              width 3em
              height 3em
              margin-right 0.75em
              border-radius 0.25em

            .nav-texts
              display flex
              flex-direction column
      &.open
        max-height 1000px

  .ctas-wrap
    background-color #fff
    margin 0.5em 0
    border-radius 1.875em
    padding 1.25em 1.5em

    .button-cta-wrap
      .site-nav-mobile-btn-cta
        .cta-content
          letter-spacing 0.04038em

        .arrow-container
          margin-right 0.4em
          height 2.5em
          width 2.5em
          background-color #fff
          border-radius 50%
          display flex
          justify-content center
          justify-items center

    .image-cta-wrap
      padding-top 1em
      .image-cta
        display block
        img
          border-radius 1.875em
          width 100%

  @media mq-tablet
    .button-cta-wrap,
    .image-cta-wrap
      padding 1em 15%

  @media mq-desktop-md
    .button-cta-wrap,
    .image-cta-wrap
      padding 1em 25%

</style>
