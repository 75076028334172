import reviewConstants from "@mrModules/review/lib/constants";
import vueReviewSvc from '@services/vueReviewSvc';

const state = {
  summary: {
    average: 0,
    count: 0
  },
  reviews: [],
  page: 1,
  pages: 0,
  itemsPerPage: 5,
  sortBy: reviewConstants.sortBy.HIGHEST,
  sortByChoices: reviewConstants.sortBy,
  sortByOptions: reviewConstants.sortByOptions,
  filters: {
    rating: [],
    ageRange: [],
    hairTypes: [],
    grayAmount: []
  },
  filterOptions: {
    rating: reviewConstants.ratingOptions,
    ageRange: reviewConstants.ageRangeOptions,
    hairTypes: reviewConstants.hairTypeOptions,
    grayAmount: reviewConstants.grayAmountOptions
  },

  loadingCategoriesSummaries: false,
  categorySummaries: null,
  totalCategoriesReviews: 0,
  totalCategoriesStars: 0,
};

const getters = {
  hasNextPage(state) {
    return state.page < state.pages;
  },

  hasPreviousPage(state) {
    return state.page > 1;
  },

  ageRangeOptions(state) {
    return state.filterOptions.ageRange;
  },

  hairTypeOptions(state) {
    return state.filterOptions.hairTypes;
  },

  grayAmountOptions(state) {
    return state.filterOptions.grayAmount;
  },

  ratingFilter(state) {
    return state.filters.rating;
  },

  ageRangeFilter(state) {
    return state.filters.ageRange;
  },

  hairTypeFilter(state) {
    return state.filters.hairTypes;
  },

  grayAmountFilter(state) {
    return state.filters.grayAmount;
  },

  hasFilters(state) {
    var hasActiveFilter = false;
    var filterKeys = Object.keys(state.filters);

    for (var x = 0; x < filterKeys.length; x++) {
      if (state.filters[filterKeys[x]] && state.filters[filterKeys[x]].length) {
        hasActiveFilter = true;
        break;
      }
    }

    return hasActiveFilter;
  },

  filterLabel: (state) => (key, value) => {
    var label = '';

    if (state.filterOptions[key]) {
      for (var x = 0; x < state.filterOptions[key].length; x++) {
        if (state.filterOptions[key][x].value == value) {
          label = state.filterOptions[key][x].label;
          break;
        }
      }
    }

    return label;
  },

  totalCategoriesAverage(state) {
    return state.totalCategoriesStars / state.totalCategoriesReviews;
  }
};

const actions = {
  getProductReviewsForPdp({ state, commit }, params) {
    // adjust page for 0 offset
    var validPage = state.page - 1 >= 0 ? state.page - 1 : 0;

    return vueReviewSvc.getProductReviewsForPdp({
      productIds: params.productIds,
      sortBy: state.sortBy,
      filters: state.filters,
      page: validPage,
      itemsPerPage: state.itemsPerPage
    }).then(res => {
      commit('setSummary', {
        average: res.data.average,
        count: res.data.totalCount,
      });
      commit('setReviews', res.data.reviews);
      commit('setPages', Math.ceil(res.data.totalCount / state.itemsPerPage));
      return res.data;
    });
  },

  openFilterModal({ dispatch }, params) {
    dispatch('modal/showModal', {
      component: 'FilterModal',
      theme: 'full-screen',
      returnFocusElement: params.returnFocusElement,
    }, { root: true });
  },

  getReviewSummariesForCategories({ state, commit, dispatch }, params) {
    if (state.loadingCategoriesSummaries) {
      return;
    }

    const { categoryIds } = params;
    commit('setLoadingCategoriesSummaries', true);
    vueReviewSvc.getReviewSummariesForCategories(params).then(res => {
      let totalReviews = 0;
      let totalStars = 0;
      let catData = res.data || {};

      commit('setCategorySummaries', catData);

      categoryIds.forEach(id => {
        totalReviews += catData[id].numReviews;
        totalStars += catData[id].ratingsTotal;
      });

      commit('setTotalCategoriesReviews', totalReviews);
      commit('setTotalCategoriesStars', totalStars);
      commit('setLoadingCategoriesSummaries', false);
    }).catch(err => {
      dispatch('notifyError', { err, silent: true }, { root: true });
      commit('setLoadingCategoriesSummaries', false);
    });
  }
};

const mutations = {
  setSummary(state, val) {
    state.summary = val;
  },

  setReviews(state, val) {
    state.reviews = val;
  },

  setPage(state, val) {
    if (val >= 1) {
      state.page = val;
    } else {
      state.page = 1;
    }
  },

  setPages(state, val) {
    state.pages = val;
  },

  nextPage(state, getters) {
    if (getters.hasNextPage) {
      state.page += 1;
    }
  },

  previousPage(state, getters) {
    if (getters.hasPreviousPage) {
      state.page -= 1;
    }
  },

  setSortBy(state, value) {
    state.sortBy = value;
  },

  setFilter(state, params) {
    if (!Object.prototype.hasOwnProperty.call(params, 'filter') || !Object.prototype.hasOwnProperty.call(params, 'value') || !Array.isArray(params.value) || !state.filters[params.filter]) {
      return;
    }
    // always reset page when changing a filter
    state.page = 1;

    state.filters[params.filter] = params.value;
  },

  toggleFilter(state, params) {
    if (!Object.prototype.hasOwnProperty.call(params, 'filter') || !Object.prototype.hasOwnProperty.call(params, 'value') || !state.filters[params.filter]) {
      return;
    }
    // always reset page when adding or removing a filter
    state.page = 1;

    let index = state.filters[params.filter].indexOf(params.value);

    if (index != -1) {
      state.filters[params.filter].splice(index, 1);
    } else {
      state.filters(params.filter).push(params.value);
    }
  },

  clearAllFilters(state) {
    state.page = 1;
    Object.keys(state.filters).forEach(key => {
      state.filters[key] = [];
    });
  },

  setCategorySummaries(state, value) {
    state.categorySummaries = value;
  },

  setTotalCategoriesReviews(state, value) {
    state.totalCategoriesReviews = value;
  },

  setTotalCategoriesStars(state, value) {
    state.totalCategoriesStars = value;
  },

  setLoadingCategoriesSummaries(state, value) {
    state.loadingCategoriesSummaries = value;
  }

};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
