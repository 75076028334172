<template lang="pug">
  // Official dash-hudson docs: https://developer.dashsocial.com/docs/shoppable-galleries-doc
  .dash-hudson-widget.container(ref="dhUgc")
</template>
<script>
  export default {
    name: "DashHudsonWidget",
    props: {
      scriptType: {
        type: String,
        required: true,
        validator: value => ["board-embed", "product-carousel-embed", "board-carousel-embed"].includes(value)
      },
      galleryId: {
        type: String,
        required: true
      },
      name: {
        type: String,
        default: "dhboard"
      },
      rowSize: {
        type: String,
        default: "4"
      },
      gapSize: {
        type: String,
        default: "4"
      },
      rowLimit: {
        type: String,
        default: "auto"
      },
      mobileRowSize: {
        type: String,
        default: "3"
      },
      mobileGapSize: {
        type: String,
        default: "2"
      },
      callToAction: {
        type: String,
        default: "shop_now"
      },
      params: {
        type: String,
        default: ''
      }
    },
    watch: {
      galleryId() {
        this.loadScript();
      }
    },
    mounted() {
      this.loadScript();
    },
    methods: {
      loadScript() {
        if (this.observer) {
          this.observer.disconnect();
        }
        const container = this.$refs.dhUgc;
        container.innerHTML = "";
        const script = document.createElement("script");
        script.src = this.scriptType == "board-embed" ? `https://cdn.dashsocial.com/web/js/board-embed.js`: `https://cdn.dashhudson.com/web/js/${this.scriptType}.js`;
        script.type = "text/javascript";
        script.setAttribute("data-name", this.name);
        script.setAttribute("data-gallery-id", this.galleryId);
        script.setAttribute("data-row-size", this.rowSize);
        script.setAttribute("data-gap-size", this.gapSize);
        script.setAttribute("data-row-limit", this.rowLimit);
        script.setAttribute("data-mobile-row-size", this.mobileRowSize);
        script.setAttribute("data-mobile-gap-size", this.mobileGapSize);
        script.setAttribute("data-call-to-action", this.callToAction);
        // Ability to add other parameters in ex: infinite, scroll-disabled (no arrows), autoplay, etc.
        for (let [key, value] of Object.entries(JSON.parse(this.params))) {
          script.setAttribute(`data-${key}`, value);
        }
        container.appendChild(script);
        this.observer = new MutationObserver(mutations => {
          mutations.forEach(mutation => {
            mutation.addedNodes.forEach(node => {
              if (node.nodeType === Node.ELEMENT_NODE && node.classList.contains("ls-container") && node.classList.contains("dh-widget-container")) {
                this.$emit("dashhudsonMounted", node);
              }
            });
          });
        });
        this.observer.observe(container, { childList: true });
      }
    }
  };
</script>
<style scoped lang="stylus">
  .dash-hudson-widget
    position relative
    overflow hidden
    .ls-container .ls-photo-wrapper 
      position relative
</style>
