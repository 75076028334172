import mrApi from './mrApi';

export default {
  getUrmProduct,
  getClientUsageWindow,
  doDowngrade,
  pauseMembership,
  unpauseMembership
};

export function getUrmProduct(params) {
  return mrApi.get('/api/urm/getUrmProduct', { params });
}

export function getClientUsageWindow(params) {
  return mrApi.get('/api/urm/getClientUsageWindow', { params });
}

export function doDowngrade(params) {
  return mrApi.post('/api/urm/downgradeMembership', params);
}

export function pauseMembership(params) {
  return mrApi.post('/api/urm/pauseMembership', params);
}

export function unpauseMembership(params) {
  return mrApi.post('/api/urm/unpauseMembership', params);
}