<template lang="pug">
  .sierra-chat-widget

</template>
<script>
  import sierraChatWidgetMixin from "@mixins/chatWidgetMixin/sierraChatWidgetMixin";

  export default {
    name: "SierraChatWidget",

    mixins: [
      sierraChatWidgetMixin,
    ],

  };
</script>
<style lang="stylus">
  .sierra-chat-widget
    position fixed
    bottom 120px
    right 20px
    display flex
    flex-direction column
    align-items center
    gap 20px

  [data-sierra-chat-launcher]
    &::part(banner)
      z-index 1
      opacity 1
      transition opacity 1.4s

  [data-sierra-chat-launcher]
    &::part(launcher)
      opacity 1
      transition opacity 1.4s


  .with-sticky-drawer  
    [data-sierra-chat-launcher]
      &::part(launcher)
        transform translateY(-60px)
      &::part(banner)
        transform translateY(-60px)

  @media mq-tablet-less
    .with-sticky-cta,.with-footer-visible
      [data-sierra-chat-launcher]
        &::part(launcher)
          opacity 0
          visibility hidden
        &::part(banner)
          opacity 0
          visibility hidden
</style>