<template lang="pug">
  .mr-marquee
    p.marquee-text(v-html="repeatedText")
</template>
<script>
  export default {
    name: "MrMarquee",
    props: {
      text: {
        type: String,
        required: true,
      },
      repeatTimes: {
        type: Number,
        default: 50,
      },
    },
    computed: {
      repeatedText() {
        let eachText = `${this.text}`.repeat(this.repeatTimes);
        return eachText;
      }
    },
  };
</script>
<style scoped lang="stylus">
  .mr-marquee
    height 20px
    color white
    overflow hidden
    position relative
    text-wrap-mode nowrap
  
    .marquee-text
      position absolute
      width min-content
      animation marquee 120s linear infinite

      span
        padding 0 10em
    
    @keyframes marquee
      0%
       transform (translateX(0%) translateZ(0))
      100%
        transform (translateX(-50%) translateZ(0))

  @media mq-tablet-less
    .mr-marquee
      .marquee-text
        animation marquee 120s linear infinite
</style>
