import vueWaitlistSvc from "@services/vueWaitlistSvc";

const state = {
  waitlists: []
};

const getters = {
  isOnWaitlist: (state) => (waitlistType, productId) => {
    return !!state.waitlists.find(list => list.waitlist_type == waitlistType && list.product_id == productId);
  }
};

const actions = {
  initWaitlists({ state, dispatch }) {
    if (state.waitlists.length) {
      return;
    }
    dispatch('getWaitlists');
  },

  getWaitlists({ commit }) {
    vueWaitlistSvc.getWaitlists().then(res => {
      commit('setWaitlists', res.data);
    });
  },

  addToWaitlist({ dispatch }, params) {
    return vueWaitlistSvc.addToWaitlist(params).then(() => {
      dispatch('getWaitlists');
    });
  }
};

const mutations = {
  setWaitlists(state, waitlists) {
    state.waitlists = waitlists;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
