const state = {
  productCategories: [],
  productTypeFilter: null,
  productTypeFilters: [],
  hairColorFamilyFilter: null,
  hairColorFamilyFilters: [],
};

const mutations = {
  setProductCategories(state, val) {
    state.productCategories = val;
  },

  setProductTypeFilter(state, val) {
    state.productTypeFilter = val;
  },

  setProductTypeFilters(state, val) {
    state.productTypeFilters = val;
  },

  setHairColorFamilyFilter(state, val) {
    state.hairColorFamilyFilter = val;
  },

  setHairColorFamilyFilters(state, val) {
    state.hairColorFamilyFilters = val;
  }
};

export default {
  namespaced: true,
  state,
  mutations,
};
