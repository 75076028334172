<template lang="pug">
  section.site-nav-desktop-v2-shop-nav(role="region" aria-label="Shop Sub Navigation")
    .nav-sections.xs-9.mb-50m
      .nav-section.xs-3.text-left(v-for="subNav in subNavs" :key="subNav.title" role="region" :aria-label="'Shop by ' + subNav.title")
        h3.f-primary.bold.text-color-3.shop-by-title SHOP BY
        h4.f-primary.narrow-letter-spacing.bold.xs-f-xmedium.cw-color-11 {{ subNav.title }}

        ul.sub-nav-items.mt-250m(role="menu")
          li.sub-nav-item.mb-125m(v-for="(navItem) in subNav.navItems" role="none" :key="navItem.link.text")
            a(:class="{'open bold': isSubNavOpen(subNav)}" :href="navItem.link.href" @click="mix_trackFTVNavClick('shop', navItem.link.text)" role="menuitem")
              img(v-if="navItem.image && showNavImgOnMediaQuery(navItem)" :src="navItem.image.url" :alt="navItem.link.text")

              .nav-texts
                p.xs-f-xsmall.f-primary.narrow-letter-spacing.bold.cw-color-11 {{ navItem.link.text }}
                p.xs-f-xxsmall.f-primary.narrow-letter-spacing.text-color-3(v-if="navItem.subText") {{ navItem.subText }}

    section.ctas.xs-3.mb-250m.text-left(role="region" aria-label="Shop Calls to Action")
      ul.shop-nav-links(role="menu" v-if="buttonCtas && buttonCtas.length > 0")
        li.shop-nav-link.xs-12.mb-100m(v-for="cta in buttonCtas" role="none" :key="cta.text")
          a.underline.upper.bold(:href="cta.href" @click="mix_trackFTVNavClick('shop', cta.text)" role="menuitem")
            | {{ cta.text }}

      .image-cta-wrap(v-if="displayMarketingSlot")
        CmsPartialSsr(:mixin-key="marketingMixinKey" role="region" aria-label="Marketing Banner")

</template>

<script>
  import SiteNavTrackMixin from "@components/SiteNav/SiteNavTrackMixin/SiteNavTrackMixin";
  import ImgBox from "@components/imgBox";
  import { mapState } from "vuex";
  import CmsPartialSsr from "@components/CmsPartialSsr";

  export default {
    name: "SiteNavDesktopV2ShopNav",
    components: {
      CmsPartialSsr,
      ImgBox,
    },
    mixins: [SiteNavTrackMixin],
    inject: ['getObjProperty'],
    props: {
      shopNav: {
        type: Object
      }
    },
    data() {
      return {
        openedSubNav: null
      };
    },

    computed: {
      ...mapState('global', ['windowData']),
      isMobileOrTablet() {
        return this.windowData.breakpoint == 'mobile' || this.windowData.breakpoint == 'tablet';
      },

      isDesktop() {
        return this.getObjProperty(this, 'windowData.breakpoint') === 'desktop' || this.getObjProperty(this, 'windowData.breakpoint') === 'desktop-md';
      },

      marketingMixinKey() {
        return this.shopNav?.marketingSlot?.mixin_key;
      },

      displayMarketingSlot() {
        return this.marketingMixinKey && this.marketingMixinKey.length > 0;
      },

      subNavs() {
        return this.shopNav.subNavs;
      },

      buttonCtas() {
        if (!this.shopNav.buttonCta) {
          return [];
        }
        if (!Array.isArray(this.shopNav.buttonCta)) {
          return [this.shopNav.buttonCta];
        }
        return this.shopNav.buttonCta;
      },
    },

    methods: {
      showNavImgOnMediaQuery(navItem) {
        return navItem.imgOnDesktop && this.isDesktop || navItem.imgOnMobile && this.isMobileOrTablet;
      },

      openSubNav(subNav) {
        this.openedSubNav = subNav;
        this.mix_trackFTVNavViewed(subNav.title);
      },

      isSubNavOpen(subNav) {
        return this.openedSubNav && this.openedSubNav.title == subNav.title;
      }
    }
  };
</script>

<style scoped lang="stylus">
.site-nav-desktop-v2-shop-nav
  position relative
  padding 2.2em 3em
  .ctas
    padding-left 1.5em
    .image-cta-wrap
      margin-top 1.2em
      max-width 400px
      .image-cta
        display block
        img
          border-radius 1.875em
          width 100%
  .shop-nav-links
    padding 0.5em
    display inline-block
  .nav-sections
    display flex
    flex-wrap wrap
    .nav-section
      padding 0 0.5em

      .shop-by-title
        font-size 11px
      .sub-nav-item
        a
          display flex
          align-items center
          img
            width 3em
            height 3em
            border-radius 0.5em
            margin-right 1em
  .left-nav
    position relative
    text-align left
    padding 1em 0
    padding-right 0.5em
    width 16em
    min-height 34em
    &.rows-3
      min-height 49em
    &.rows-4
      min-height 65em
    .sub-nav-title
      padding 0.25em 0
      margin 0.5em 0
      width 100%
      text-align left
      .sub-nav-title-content
        display inline-block
      &.open
        color text-color-1
        .sub-nav-title-content
          border-bottom 3px solid brand-color-1
    .right-nav
      position absolute
      top 0
      left 17em
      width 41em
      display flex
      flex-direction row
      flex-wrap wrap
      justify-content space-between
      padding 1.5em 0
      li
        width 31%
        a
          display inline-block
          padding-bottom 1em
          margin-bottom 0.25em
          width 100%
          img
            width 100%
            border-radius 0.5em
          &.no-image
            white-space nowrap
            .a-box-content
              border-radius 0.5em
</style>
