import vueXsellSvc from '@services/vueXsellSvc';

export default {
  data() {
    return {
      xsellUpdated: 0
    };
  },

  computed: {
    bestMatches() {
      if (!this.xsellByContext) {
        return [];
      }
      return this.xsellByContext.bestMatches;
    },

    cartAddons() {
      if (!this.xsellByContext) {
        return [];
      }
      return this.xsellByContext.historyAddons;
    },

    historyAddons() {
      if (!this.xsellByContext) {
        return [];
      }
      return this.xsellByContext.historyAddons;
    },

    lastOrderAddons() {
      if (!this.xsellByContext) {
        return [];
      }
      return this.xsellByContext.lastOrderXSell;
    },

    subscriptionAddons() {
      if (!this.xsellByContext) {
        return [];
      }
      return this.xsellByContext.subscriptionXSell;
    }
  },

  asyncComputed: {
    xsell: {
      lazy: true,
      get() {
        return vueXsellSvc.getXsellItems().then(res => res.data);
      },
      watch() {
        this.xsellUpdated;
      }
    },

    xsellByContext: {
      lazy: true,
      get() {
        return vueXsellSvc.getXsellByContext().then(res => res.data);
      },
      watch() {
        this.xsellUpdated;
      }
    }
  }
};