<!--
  This modal works through Vuex, it enables easier debugging
  using Vue Devtools. It's attached to mrVueApp and can be called
  from anywhere within the app. Just pass in a path to a component you
  wish to display in the modal.

  Ensure that component gets registered somewhere, usually in the component it will be used from.

      import AddressEditModal from './AddressEditModal';

      export default {
        name: "AddressBook",
        components: {
          AddressEditModal,
          AddressDeleteModal
        },

  To open it dispatch showModal to the state passing:
      methods: {
        editAddressModal() {
          this.$store.dispatch('modal/showModal', { component: './AddressBook/AddressEditModal' });
        }
      }

  In template:
      button(@click="editAddressModal()") Open Your modal

-->
<template lang="pug">
    .vue-app-modal.max-at-tweak(:class="classes")
      transition(name="modal-background-fade")
        .modal-backdrop(v-if="visible" @click.self.stop="backgroundClick" :class="veilClass")
      transition(:name="effect")
        focus-lock.app-modal(v-if="visible" :disabled="!visible")
          .modal-body(role="dialog" aria-modal="true" aria-label="modal" tabindex="0" body-scroll-lock-ignore)
            .notifications-container(v-if="internalNotifications && internalNotifications.length")
              CustomNotifications(:notifications="internalNotifications" @remove-notification="removeNotification")

            button.btn-close.xs-f-xmedium.max-at-tweak(v-if='!disableClose' type="button" @click="xClose" aria-label="Close modal" data-mr-ass="close-modal")
              span.icon-x

            .modal-body-inner(ref="modal-body-inner")
              component(:is="component" v-bind="modalProps" @exit-modal="exitModal" @hide-modal="hideModal" @notification-emmited="pushInternalNotification" @scroll-top="scrollToTop")

          button.btn-back.transparent-btn.xs-f-large.max-at-tweak(v-if="stepBackActions.length" type="button" @click="stepBack" aria-label="Go back")
            i.icon-arrow-back.clickable

</template>

<script>
  import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock-upgrade';

  import Vue from 'vue';
  import { mapState, mapActions } from 'vuex';
  import CustomNotifications from "@components/CustomNotifications";

  export default {
    name: 'AppModal',

    components: {
      CustomNotifications,
    },

    data() {
      return {
        component: null,
        internalNotifications: [],
      };
    },

    computed: {
      ...mapState('global', ['windowData']),

      ...mapState('modal', [
        'visible',
        'theme',
        'persistent',
        'modalComponent',
        'modalProps',
        'stepBackActions',
        'disableClose',
        'desktopEffect',
        'mobileEffect',
      ]),

      ...mapState('pdp', ['product']),

      effect() {
        const isMobile = this.windowData.breakpoint === 'mobile';
        if (isMobile) {
          return this.mobileEffect || 'modal-body-fade';
        }
        return this.desktopEffect || 'modal-body-fade';
      },

      veilClass() {
        return this.theme == 'redirect-modal' ? 'bg-black-a20' : 'bg-black-a40';
      },

      classes() {
        if (this.visible) {
          disableBodyScroll(this.$el, {
            reserveScrollBarGap: true,
            allowTouchMove: el => {
              while (el && el !== document.body) {
                if (el.getAttribute('body-scroll-lock-ignore') !== null) {
                  return true;
                }

                el = el.parentNode;
              }
            },
          });
        } else {
          if (this.$el) {
            enableBodyScroll(this.$el);
          }
        }

        return [...(this.theme || '').split('.'), this.visible ? 'visible' : ''].filter(Boolean);
      },
    },

    watch: {
      modalComponent(component) {
        if (!component) {
          return;
        }

        if (typeof component == 'object') {
          this.component = Vue.util.extend({}, component);
          return;
        }

        const componentName = component.split('/').pop();
        if (!/Modal/.test(componentName) || /\.test\.js/.test(componentName)) {
          const customerErrorMessage = 'Something went wrong and your action could not be completed';
          const sentryErrorMessage = `INVALID_MODAL: Invalid modal name, modals should finish with "Modal". Received: ${component}`;
          this.notifyError(customerErrorMessage, new Error(sentryErrorMessage));
        }

        Vue.component(componentName, () =>
          import('./modals/' + componentName + '.js')
        );

        this.component = componentName;

        // change recommendationContext for DY
        if (window.DY) {
          let dataArray = [];
          let componentNameMap = {
            "PurchasePanelV2Modal": "Purchase Panel",
            "PdpXsellCombinedModal": "Purchase Flow X-sell"
          };

          if (componentName) {
            if (componentNameMap[componentName]) {
              dataArray.push(componentNameMap[componentName]);
            } else {
              dataArray.push(componentName);
            }
          }

          if (this.product && this.product.code) {
            dataArray.push(this.product.product_type);
            dataArray.push('' + this.product.id);
          }

          window.DY.recommendationContext = {type:'OTHER', data: dataArray};
        }
      },

      stepBackActions(actions) {
        if (!actions.length) {
          clearAllBodyScrollLocks();
        }
      },
    },

    mounted() {
      document.body.addEventListener('keydown', e => {
        if (e.keyCode === 27) {
          this.exitModal();
        }
      });
    },

    methods: {
      ...mapActions(['notifyError']),

      ...mapActions('modal', ['hideModal', 'executeStepBackAction']),

      exitModal() {
        if (!this.disableClose) {
          this.$emit('exit-modal');
          this.hideModal();
        }
      },

      backgroundClick() {
        if (this.persistent) {
          return;
        }

        this.exitModal();
      },

      stepBack() {
        this.hideModal();
        this.executeStepBackAction();
      },

      xClose() {
        this.trackMREvent(`Modal - ${this.component} - X Close`);
        this.exitModal();
      },

      removeNotification(id) {
        this.internalNotifications = this.internalNotifications.filter(notification => notification.id !== id);
      },

      pushInternalNotification(notification) {
        notification.id = this.internalNotifications.length;
        notification.time = notification.time || 10000;

        this.internalNotifications.push(notification);

        setTimeout(() => this.removeNotification(notification.id), notification.time);
      },

      scrollToTop() {
        if (this.$refs['modal-body-inner']) {
          this.$refs['modal-body-inner'].scrollTop = 0;
        }
      }
    },
  };
</script>

<style lang="stylus">
  .vue-app-modal
    modal-border-radius = 0.5em
    modal-border-radius-big = 1em
    -webkit-overflow-scrolling touch

    &.visible
      position fixed
      top 0
      right 0
      bottom 0
      left 0
      overflow auto
      z-index 9999999

    .modal-backdrop
      position fixed
      top 0
      bottom 0
      left 0
      right 0
      z-index 200

    [data-lock]
      height 100%
      width 100%
      overflow auto

    .app-modal
      position absolute
      top 3em
      left 50%
      transform(translateX(-50%))
      max-width 100%
      z-index 10000000
      background-color color-white
      border 1px solid color-fog-light
      border-radius modal-border-radius
      overflow hidden
      margin-bottom 5em

    .modal-body
      padding 2em 10% 2em
      overflow-x hidden
      overflow-y auto
      margin auto
      position relative

      .notifications-container
        position absolute
        left 0em
        right 0em
        top 0em
        width 100%
        height 12em
        display block
        z-index 11

        .custom-notify-box
          width 100%

    .btn-close
      z-index 10
      top 0.5em
      right 0.5em
      position absolute
      color brand-color-2
      border none
      background-color ui-color-3
      border-radius 50%
      height 1.8em
      width 1.8em

      .icon-x
        position absolute
        top 50%
        left 50%
        transform translate(-50%, -50%)

    .btn-back
      top 0
      left 0
      position absolute
      padding 0.5em
      color text-color-1

    .modal-background-fade-enter-active
    .modal-background-fade-leave-active
      transition opacity .2s

    .modal-background-fade-enter
    .modal-background-fade-leave-to
      opacity 0

    .modal-body-fade-enter-active
    .modal-body-fade-leave-active
      transition all .3s

    .modal-body-fade-enter
    .modal-body-fade-leave-to
      top 0em
      opacity 0

    @media mq-mobile-tweak-lower
      .app-modal
        top 0
        left 0
        right 0
        border none
        border-radius 0
        transform none
        margin-bottom 0

        .modal-body
          padding 1em 3% 2em

    @media mq-mobile-tweak-upper
      .app-modal
        top 0
        left 2.5%
        right 0
        transform none
        max-width 95%

        .modal-body
          padding 1em 3% 2em

    @media mq-tablet
      .app-modal
        width 43em
        max-width 90%

        .modal-body
          padding 1em 8% 2em

    @media mq-desktop-md-plus
      .app-modal
        width 48em
        max-width 90%

      .narrow-modal-body
        width 33em
        margin 0 auto

    &.no-padding
      .modal-body
        padding 0

    &.half-padding
      @media mq-tablet
        .modal-body
          padding-left 4%
          padding-right 4%

      @media mq-desktop-md-plus
        .modal-body
          padding-left 5%
          padding-right 5%

    &.modal-reset
      .app-modal
        border unset
        border-radius unset
        width unset

        .modal-body
          padding 0

        .btn-close
          color text-color-1

        .btn-back
          display none

    &.reset-width
      .app-modal
        max-width initial
        width initial

    &.reset-padding
      .app-modal
        .modal-body
          padding 0

    &.reset-side-padding
      .app-modal
        .modal-body
          padding-right 0 !important
          padding-left 0 !important

    &.close-left
      .btn-close
        left 0
        right auto

    &.bg-ui-color-3
      .app-modal
        .modal-body
          background-color ui-color-3

    &.hcb-location-search-result
      .app-modal
        width 64em
        top 15%
        background-color ui-color-3

        .modal-body
          padding 2em

      @media mq-mobile
        .app-modal
          width 100%
          height 100%
          max-width 100%
          top 0
          margin 0
          border-radius 0
          .modal-body
            height 100%

    &.app-modal-xxsmall
      .app-modal
        max-width 26em

        .modal-body
          padding 2em 7% 2em


        @media mq-mobile-tweak-lower
          max-width none

        @media mq-mobile-tweak-upper
          max-width 23em
          width 23em
          left 50%
          transform(translateX(-50%))

    &.app-modal-xsmall
      .app-modal
        max-width 360px

        .modal-body
          padding 2em 7% 2em


        @media mq-mobile
          max-width none

    &.app-modal-small
      .app-modal
        max-width 500px


    &.app-modal-medium
      @media mq-desktop-md-plus
        .app-modal
          width 54em
          max-width 90%

        .narrow-modal-body
          width 33em
          margin 0 auto

    &.pro-plus-v2
      .app-modal
        border none
        border-radius 0.5em
        background-color #f3eff3

      @media mq-mobile
        .app-modal
          top 1em
          left 3%
          right 3%

      @media mq-desktop-plus
        .app-modal
          width 62em

    &.app-modal-overflow-visible
      .app-modal
        overflow visible
        // Fixing dropdown issue in DOTCOMPB-5275, safari does not inherit overflow visible from parent.
        .modal-body
          overflow visible

    &.signin
      .app-modal
        max-width 500px

        @media mq-mobile
          bottom 0

        .modal-body
          padding 0

          @media mq-mobile
            height 100%

          .sign-in-wrap
            margin 0
            max-width none

        .modal-body
          padding 0

      @media mq-mobile-tweak-upper
        .app-modal
          width bp-mobile-tweak
          left 50%
          transform(translateX(-50%))

    &.try-it-on-modal
      .app-modal
        border unset
        border-radius unset
        width unset

        .modal-body
          padding 0

        .btn-close
          display none

      @media mq-mobile
        .app-modal
          width 100%
          height 100%
          max-width 100%
          top 0
          margin 0
          border-radius 0

          .modal-body
            height 100%

      @media mq-desktop-md-plus
        .app-modal
          background-color color-fog-light

    &.bottom-modal
      @media mq-mobile
        .app-modal
          top auto
          bottom 0
          width 100%

        &.top-border-radius
          .app-modal
            border-top-right-radius modal-border-radius
            border-top-left-radius modal-border-radius

      @media mq-mobile-tweak-upper
        .app-modal
          top auto
          bottom 0
          max-width 100%
          margin-bottom 0

    &.right-modal
      .app-modal
        top 0
        right 0
        bottom 0
        left auto
        border-radius 0
        transform none
        margin-bottom 0
        width 30em
        background ui-color-3

        .modal-body
          padding 0
          height 100vh

      @media mq-tablet-plus
        .btn-close
          display none

    &.right-modal
      .app-modal
        top 0
        right 0
        bottom 0
        left auto
        border-radius 0
        transform none
        margin-bottom 0
        width 30em
        background ui-color-3

        .btn-close
          left 0
          right auto
          display block

    &.right-modal-small
      .app-modal
        width 20em

        .modal-body
          padding 2em 5% 2em

    &.service-quick-view
      .app-modal
        max-width 900px
        width 100%
        border-radius 16px

      @media mq-mobile
        .app-modal
          border-radius 0px

          .modal-body
            padding 0px

    &.service-phone-only
      @media mq-mobile
        .app-modal
          top 3em

    &.blur
      .app-modal
        background rgba(255, 255, 255, 0.5)
        -webkit-backdrop-filter: blur(1rem);
        backdrop-filter blur(1rem)

      .modal-backdrop
        background-color unset

    &.full-screen
      .app-modal
        width 100%
        height 100%
        max-width 100%
        top 0
        margin 0
        border-radius 0

        div[data-lock]
          width 100%
          height 100%

        .modal-body
          width 100%
          height 100%
          padding 0

      &:not(.show-top-close-btn)
        .btn-close
          display none

    &.image-carousel
      .app-modal
        width 32em

      @media mq-mobile-tweak-upper
        .app-modal
          width 90%

    &.flex-width
      .app-modal
        width auto

      @media mq-mobile-tweak-upper
        .app-modal
          width 90%

    &.redirect-modal
      .app-modal
        width 25em
        top 25%

    &.center-modal
      .app-modal
        width 25em
        top 25%

      @media mq-mobile
        .app-modal
          height 80%
          width 90%
          top 10%
          left 5%
          .modal-body
            padding-top 15%

    &.center-modal-full-screen
      .app-modal
        width 25em
        top 25%

        .modal-body
          padding-bottom 3em

      @media mq-mobile
        .app-modal
          height 100%
          width 100%
          top 0%

          .modal-body
            height 100%
            width 100%
            padding-top 3em

    &.more-border-radius
      .app-modal
        border-radius modal-border-radius-big

      @media mq-mobile
        .app-modal
          border-radius 0px

    &.more-border-radius-and-mobile-fullscreen
      .app-modal
        border-radius modal-border-radius-big

      @media mq-mobile
        .app-modal
          width 100%
          height 100%
          max-width 100%
          top 0
          margin 0
          border-radius 0

          div[data-lock]
            width 100%
            height 100%

          .modal-body
            width 100%
            height 100%
            display flex
            flex-direction column
            align-items center
            justify-content center

    &.mobile-drawer
      @media mq-mobile
        .app-modal
          top auto
          bottom 0
          margin-bottom 0
          border-top-right-radius modal-border-radius
          border-top-left-radius modal-border-radius
          border-bottom-right-radius 0
          border-bottom-left-radius 0
          max-height 90vh
          overflow auto
          width 100%
          max-width 100%

    &.post-purchase
      .app-modal
        width 40em
        margin-bottom 3em
        border-radius 0.5em

      .modal-body
        padding 0
        padding-top 1.5em

      @media mq-mobile
        .app-modal
          top 1.5em
          left 50%
          transform(translateX(-50%))
          right initial
          width 25em

      @media mq-desktop-md-plus
        .app-modal
          width 45em

    .scroll-bottom-enter-active,
    .scroll-bottom-leave-active
      transition all .2s ease-out

    .scroll-bottom-enter,
    .scroll-bottom-leave-to
      transform(translateY(100%))

    &.bottom-mobile-slide,
    &.bottom-mobile-slide-no-padding
      @media mq-mobile
        .app-modal
          display flex
          position fixed
          top auto !important
          max-height 90vh
          bottom 0
          margin-bottom 0
          border-top-right-radius modal-border-radius-big
          border-top-left-radius modal-border-radius-big
          border-bottom-right-radius 0px
          border-bottom-left-radius 0px

          .modal-body
            padding 1em 5%
            overflow hidden
            max-height 90vh

          .modal-content
            max-height 90vh
            overflow auto

      @media mq-mobile-tweak-upper
        .app-modal
          width bp-mobile-tweak
          left 50%
          transform(translateX(-50%))

    .scroll-right-enter-active,
    .scroll-right-leave-active
      transition all .2s ease-out

    .scroll-right-enter,
    .scroll-right-leave-to
      transform translateX(100%) !important
      opacity 0

    &.account-modal
      .app-modal
        border-top-left-radius 16px
        border-bottom-left-radius 16px
        width 26em
        background-color color-white

        .modal-body
          padding 0

        .btn-close
          left 0
          right auto
          display block

      @media mq-mobile
        .app-modal
          width 90%

    &.bottom-mobile-slide-no-padding
      .modal-body
        padding 0

      @media mq-mobile
        .app-modal
          top 0 !important
          .modal-body
            padding 0

    &.black-background
      .modal-backdrop
        background-color ui-color-9

      .app-modal
        border-color transparent
        background transparent

    &.mobile-centered
      .app-modal
        width 36em
        top 10%

      @media mq-mobile
        .app-modal
          height 80%
          width 90%
          top 10%
          left 5%
          .modal-body
            padding-top 15%

    &.all-centered
      .app-modal
        top 25%
        width 30em

        .modal-body
          padding 24px

    &.no-padding-white-close-btn
      .app-modal
        .modal-body
          padding 0
        .btn-close
          color color-white

    @media mq-tablet-plus
      &.thin-modal
        .app-modal
          width 32em

    &.promo-input-modal
      .app-modal
        max-width 32em
        .modal-body
          padding 1em

    &.advisor-modal
      .app-modal
        width 28em
        top 15%
        border none

        .modal-body
          padding 0
      @media mq-tablet-plus
        .app-modal
          width 32em
      @media mq-mobile
        .app-modal
          display relative
          width 90%
          top 10%
          left 5%
          right 5%
          border-radius 0
          .modal-body
            height 100%

    &.booking-enhancements
      .app-modal
        border-radius 2em
        margin 1.5em 1.5em
        padding 0
        overflow visible

        .modal-body
          padding 0
          overflow visible

          .modal-body-inner
            border-radius 2em
            overflow auto
            max-height 80vh

          &:after
            content ""
            display block
            position absolute
            bottom -3em
            left 0

        .btn-close
          background-color white
          border-radius 50%
          padding 0.2em
          top -2em

          .icon-x
            color brand-color-1

        @media mq-tablet-less
          .btn-close
            top 0.5em
            right 0.5em
            background-color ui-color-3

    &.booking-learn-more
      .app-modal
        border-radius 2em
        margin 1.5em 1.5em
        padding 0
        overflow visible

        .modal-body
          padding 0
          overflow visible

          .modal-body-inner
            border-radius 2em
            overflow auto
            max-height 90vh

</style>