<template lang="pug">
  .mounted-flag
</template>
<script>
  export default {
    props: {
      flag: {
        type: String,
        default: ""
      }
    },

    mounted() {
      if (this.flag) {
        document.body.classList.add(this.flag);
      }
    },

    destroyed() {
      if (this.flag) {
        document.body.classList.remove(this.flag);
      }
    },
  };

</script>
<style lang="stylus" scoped>
  .mounted-flag
    display none
</style>