<template lang="pug">
  nav.site-nav-wrap.xs-12(:class="{ open: siteNavMobileNavOpen }")
    template(v-if="showMobileNav")
      SiteNavMobileV2(
        v-if="showFtvNavRedesign"
        :shop-nav="dynamicShopNav"
        :nav-items="dynamicNavItems"
        :nav-links="dynamicNavLinks"
        :about-nav="aboutNavExtraConfig"
      )
      SiteNavMobile(v-else)
    template(v-else)
      SiteNavDesktopV2(
        v-if="showFtvNavRedesign"
        :shop-nav="dynamicShopNav"
        :nav-items="dynamicNavItems"
        :nav-links="dynamicNavLinks"
        :about-nav="aboutNavExtraConfig"
      )
      SiteNavDesktop(v-else)

</template>

<script>
  import SiteNavDesktop from "./SiteNavDesktop";
  import SiteNavMobile from "./SiteNavMobile";
  import { mapGetters, mapState, mapActions } from "vuex";
  import SiteNavDesktopV2 from "@components/SiteNav/SiteNavDesktopV2";
  import SiteNavMobileV2 from "@components/SiteNav/SiteNavMobileV2";

  export default {
    name: "SiteNav",

    components: {
      SiteNavDesktop,
      SiteNavMobile,
      SiteNavDesktopV2,
      SiteNavMobileV2,
    },

    computed: {
      ...mapGetters('customer', [
        'isCustomerPresent',
      ]),

      ...mapState('global', ['isVueSSRApp']),

      ...mapState('siteNav', [
        'siteNavMobileNavOpen',
        'dynamicShopNav',
        'dynamicNavItems',
        'dynamicNavLinks',
        'aboutNavExtraConfig',
      ]),

      ...mapState('global', [
        'windowData'
      ]),

      showMobileNav() {
        const { breakpoint } = this.windowData;
        return !['desktop', ''].includes(breakpoint);
      },

      showV2() {
        return this.experiments['Site Nav Redesign 2025'] && this.experiments['Site Nav Redesign 2025'] == 'B';
      },

      showFtvNavRedesign() {
        return this.showV2 && !this.isCustomerPresent;
      },
    },

    methods: {
      ...mapActions('siteNav', [
        'loadDynamicNav',
      ]),
    },

    mounted() {
      if (this.experiments['Site Nav Redesign 2025']) {
        this.mix_trackExperimentViewed('Site Nav Redesign 2025');
      }
    },

    async created() {
      if (!this.isVueSSRApp) {
        await this.loadDynamicNav();
      }
    },

    async serverPrefetch() {
      if (this.isVueSSRApp) {
        await this.loadDynamicNav();
      }
    }
  };
</script>

<style scoped lang="stylus">
  @media mq-desktop-md-less
    nav.site-nav-wrap
      position fixed
      right 0
      top 0
      bottom 0
      display flex
      align-self flex-start
      flex-direction column
      left 0
      transform translateX(-100%)
      transition(transform 0.3s ease-in-out)
      background-color color-white

      &.open
        transform none
        transition(transform 0.3s ease-in-out)

      .site-nav-item
      .site-nav-title
        width 100%
        display block
        text-align left

      #extole_zone_global_header
        display inline-block

</style>
