import mrApi from './mrApi';

export default {
  getProductById,
  getProductsById,
  getProductByCode,
  getProductByUrlKey,
  getProductsByType,
  getActiveVisibleProductsByType,
  getActiveVisibleProductsByTypes,
  getActiveVisibleProductsByTypeGroupedByColorTags,
  getAverageReviewsByProductId,
  getColorsByColorFamily,
  getColorSolveProductMap,
};

export function getProductById(params) {
  return mrApi.get('/api/product/getProductById', { params });
}

export function getProductByCode(params) {
  return mrApi.get('/api/product/getProductByCode', { params });
}

export function getProductByUrlKey(params) {
  return mrApi.get('/api/product/getProductByUrlKey', { params });
}

export function getProductsById(params) {
  return mrApi.get('/api/product/getProductsById', { params });
}

export function getProductsByType(params) {
  return mrApi.get('/api/product/getProductsByType', { params });
}

export function getActiveVisibleProductsByType(params) {
  return mrApi.get('/api/product/getActiveVisibleProductsByType', { params });
}

export function getActiveVisibleProductsByTypes(params) {
  return mrApi.get('/api/product/getActiveVisibleProductsByTypes', { params });
}

export function getActiveVisibleProductsByTypeGroupedByColorTags(params) {
  return mrApi.get('/api/product/getActiveVisibleProductsByTypeGroupedByColorTags', { params });
}

export function getColorsByColorFamily(params) {
  return mrApi.get('/api/product/getColorsByColorFamily', { params });
}

export function getAverageReviewsByProductId(params) {
  return mrApi.get('/api/product/getAverageReviewsByProductId', { params });
}

export function getColorSolveProductMap() {
  return mrApi.get('/api/product/getColorSolveProductMap');
}
