import vueAdvisorSvc from '@services/vueAdvisorSvc';
import vueProductSvc from '@services/vueProductSvc';
import vueXsellSvc from '@services/vueXsellSvc';
import { isNum } from '@utilities/mrVueUtils';

const state = {
  boostRecommendations: [],
  showBoostOptions: false,
  proBoostSeal: {},
  selectedBoost: {},
  proProduct: {},
  xsellModalTitle: null,
  xsellItems: [],
  membershipXsellProducts: [],
  xsellForActiveColorKitSubs: [],
  productXsellItems: [],
  loadingXsell: false,
  xsellLoaded: false,
  loadingProductXsell: false,
  productXsellLoaded: false,
  loadingProductXsellPromise: null,
  proProductAdded: false
};

export const getters = {
  xsellGloss(state) {
    var gloss = null;

    if (state && state.productXsellItems && state.productXsellItems.length) {
      for (var x = 0; x < state.productXsellItems.length; x++) {
        if (state.productXsellItems[x].product_type == 'gloss') {
          gloss = state.productXsellItems[x];
          break;
        }
      }
    }

    return gloss;
  },

  proProductIsBundle(state) {
    return state.proProduct.product_type == 'bundle';
  }
};

export const actions = {
  initXsellItems({ state, dispatch }) {
    if (state.xsellItems.length) {
      return;
    }

    dispatch('getXsellItems');
  },

  getXsellItems({ state, commit }) {
    if (state.loadingXsell) {
      return;
    }

    commit('setLoadingXsell', true);

    vueXsellSvc.getXsellItems().then(res => {
      commit('setXsellItems', res.data);
      commit('setXsellLoaded', true);
    }).finally(() => commit('setLoadingXsell', false));
  },

  initMembershipXsellProducts({ dispatch, state }) {
    if (state.membershipXsellProducts.length) {
      return;
    }

    dispatch('getMembershipXsellProducts');
  },

  getMembershipXsellProducts({ commit }) {
    vueXsellSvc.getMembershipXsellProducts().then(res => commit('setMembershipXsellProducts', res.data));
  },

  getXsellForActiveColorKitSubs({ commit }) {
    vueXsellSvc.getXsellForActiveSubsOfProductType({ productType: 'color_kit' }).then(res => commit('setXsellForActiveColorKitSubs', res.data));
  },

  getXsellItemsForProduct({ state, commit }, params) {
    if (state.loadingProductXsell) {
      return state.loadingProductXsellPromise;
    }

    let loadingXsellPromise = new Promise((resolve, reject) => {
      commit('setLoadingProductXsell', true);

      return vueXsellSvc.getXsellItemsForProduct(params).then(res => {
        commit('setProductXsellItems', res.data);
        commit('setProductXsellLoaded', true);
        resolve();
      }).catch(() => {
        reject();
      }).finally(() => {
        commit('setLoadingProductXsell', false);
      });
    });
    commit('setLoadingProductXsellPromise', loadingXsellPromise);
    return loadingXsellPromise;
  },

  initBoostRecommendations({ commit, dispatch, state }) {
    if (state.boostRecommendations.length) {
      if (!state.selectedBoost.id) {
        commit('setSelectedBoost', state.boostRecommendations[0]);
      }
      return;
    }

    dispatch('getBoostRecommendations');
  },

  getBoostRecommendations({ state, commit }) {
    return vueAdvisorSvc.getBoostRecommendations()
      .then(res => {
        let boosts = res.data;
        //- populate necessary props for frontend
        boosts.forEach(boost => {
          boost.qty = 1;
          boost.subscribe = true;
        });
        commit('setBoostRecommendations', boosts);
        commit('setSelectedBoost', state.boostRecommendations[0]);
        commit('setProBoostSeal', state.boostRecommendations.find(boost => boost.code === 'PROBOOST-SEAL'));
      });
  },

  getProProduct({ commit }, id) {
    return new Promise((resolve, reject) => {
      vueProductSvc.getProductById({ id }).then(res => {
        // pro product needs to be active and visible
        if (res.data && res.data.status && res.data.visible && res.data.inventory_status && res.data.inventory_status == 'active') {
          commit('setProProduct', res.data);
        }
        resolve();
      }, reject);
    });
  },

  xsellOnAdvisorUpdate({ dispatch }) {
    dispatch('getBoostRecommendations');
  },

  openPdpXsellCombinedModal({dispatch}) {
    dispatch('modal/showModal', {
      component: 'PdpXsellCombinedModal',
      theme: 'full-screen',
    }, {root: true});
  },

  addedProProduct({commit}) {
    commit('setProProductAdded', true);
  }
};

export const mutations = {
  setXsellItems(state, val) {
    state.xsellItems = val;
  },

  setMembershipXsellProducts(state, val) {
    state.membershipXsellProducts = val;
  },

  setXsellForActiveColorKitSubs(state, val) {
    state.xsellForActiveColorKitSubs = val;
  },

  setBoostRecommendations(state, val) {
    state.boostRecommendations = Array.isArray(val) ? val : [];
  },

  setSelectedBoost(state, val) {
    state.selectedBoost = val || {};
  },

  setProBoostSeal(state, val) {
    state.proBoostSeal = val || {};
  },

  setBoostQty(state, val) {
    if (!isNum(val)) {
      return;
    }

    state.selectedBoost.qty = +val;
  },

  setBoostSubscription(state, val) {
    state.selectedBoost.subscribe = val;
  },

  setShowBoostOptions(state, val) {
    state.showBoostOptions = val;
  },

  setProProduct(state, val) {
    state.proProduct = val || {};
    state.proProduct.featured = true;
  },

  setLoadingXsell(state, val) {
    state.loadingXsell = Boolean(val);
  },

  setXsellLoaded(state, val) {
    state.xsellLoaded = Boolean(val);
  },

  setProductXsellItems(state, val) {
    state.productXsellItems = val;
  },

  setLoadingProductXsell(state, val) {
    state.loadingProductXsell = Boolean(val);
  },

  setProductXsellLoaded(state, val) {
    state.productXsellLoaded = Boolean(val);
  },

  setLoadingProductXsellPromise(state, val) {
    state.loadingProductXsellPromise = val;
  },

  setXsellModalTitle(state, val) {
    state.xsellModalTitle = val;
  },

  setProProductAdded(state, val) {
    state.proProductAdded = val;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
