import vueCustomerSvc from "@services/vueCustomerSvc";

const state = {
  invoices: [],
  loadingInvoices: false,
  loadingError: false,
  invoicePageNumber: 0,
  invoicePageSize: 5,
  invoiceLastPage: false,
  reviewableProducts: [],
  loadingReviewableProducts: false
};

export const getters = {
  getAppointmentsHistory: (state) => {
    return state.invoices.filter(invoice => invoice.invoice?.appointment_id);
  },
};

export const actions = {
  getPaginatedPurchaseHistory({ state, commit }) {
    commit('setLoadingInvoices', true);
    commit('setLoadingError', false);

    let payload = {
      size: state.invoicePageSize,
      page: state.invoicePageNumber
    };

    vueCustomerSvc.getPaginatedPurchaseHistory(payload).then((res) => {
      commit('addInvoices', res.data.orders || []);

      if (!res.data.next) {
        commit('setInvoiceLastPage', true);
      }

      commit('setPageNumber');
    }).catch(() => {
      commit('setLoadingError', true);
    }).finally(() => {
      commit('setLoadingInvoices', false);
    });
  },

  getReviewableProductsForCustomer({ commit }) {
    commit('setLoadingReviewableProducts', true);

    vueCustomerSvc.getReviewableProductsForCustomer().then((res) => {
      commit('setReviewableProducts', res.data);
    }).finally(() => commit('setLoadingReviewableProducts'), false);
  }
};

export const mutations = {
  addInvoices(state, val) {
    let newInvoices = Array.isArray(val) ? val : [];
    state.invoices = state.invoices.concat(newInvoices);
  },

  setInvoiceLastPage(state, val) {
    state.invoiceLastPage = Boolean(val);
  },

  resetInvoices(state) {
    state.invoices = [],
    state.invoicePageNumber = 0;
  },

  setPageNumber(state) {
    state.invoicePageNumber += 1;
  },

  setLoadingInvoices(state, val) {
    state.loadingInvoices = Boolean(val);
  },

  setLoadingError(state, val) {
    state.loadingError = !!val;
  },

  setReviewableProducts(state, val) {
    state.reviewableProducts = Array.isArray(val) ? val : [];
  },

  setLoadingReviewableProducts(state, val) {
    state.loadingReviewableProducts = Boolean(val);
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
