<template lang="pug">
  .chat-widget-container(v-if="loaded")
    SierraWidget(v-if="sierraExperiment")
    GladlyWidget(v-if="!sierraExperiment && !hideChatWidget")

</template>

<script>
  import GladlyWidget from "@components/GladlyWidget";
  import SierraWidget from "@components/SierraWidget";
  import { mapState } from 'vuex';

  export default {
    name: "ChatWidget",

    components: {
      GladlyWidget,
      SierraWidget,
    },

    data() {
      return {
        loaded: false
      };
    },

    computed: {
      ...mapState('chatWidget', ['overrideHideChat']),

      hideChatWidget() {
        return Boolean(
          (window && window.contentData && window.contentData.hideChatWidget) || this.overrideHideChat
        );
      },

      sierraExperiment() {
        return this.experiments?.sierraAiExperiment === "B";
      }
    },

    mounted() {
      this.$nextTick().then(() => {
        this.loaded = true;
      });
      if (this.experiments?.sierraAiExperiment) {
        this.mix_trackExperimentViewed('sierraAiExperiment');
      }

    },

  };
</script>