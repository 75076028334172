import vueCustomerSvc from '@services/vueCustomerSvc';
import vueExperimentSvc from '@services/vueExperimentSvc';
import { getObjProperty } from '@utilities/mrVueUtils';


export function requiresLogin(to, from, next) {
  vueCustomerSvc.getLoggedInInfo().then(async (resLoggedInInfo) => {

    const guestCheckoutRes = await vueExperimentSvc.getVariantForExperiment({
      params: {
        name: 'Guest Checkout 2024'
      },
    });

    const loggedIn = getObjProperty(resLoggedInInfo, 'data.loggedIn');
    const guestCheckoutKey = guestCheckoutRes?.data?.variantKey;

    // normal flow goes through signin
    if (!loggedIn && (guestCheckoutKey !== 'B' || (guestCheckoutKey == 'B' && to?.query?.type !== 'card'))) {
      window.location = notLoggedInLocation(to.fullPath);
      return next(false);
    }

    // guest checkout if variant is b, not already logged in, and not a third party payment
    next();
  }).catch(() => {
    window.location = notLoggedInLocation(to.fullPath);
    return next(false);
  });
}

export default [
  {
    path: '/cart', name: 'cart', component: () => {
      if (window.experiments && window.experiments['Cart Redesign 2023'] === 'B') {
        return import(/* webpackChunkName: "cac-cart-v2.1" */ '../CartV2');
      } else {
        return import(/* webpackChunkName: "cac-cart" */ '../Cart');
      }
    }
  }, {
    path: '/checkout', name: 'checkout', beforeEnter: requiresLogin, component: async () => {
      return import(/* webpackChunkName: "cac-checkout" */ './Checkout');
    }
  }
];

export function notLoggedInLocation(path) {
  let signInView;

  if (path.includes('checkout')) {
    signInView = '&view=create';
  }

  return `/notloggedin?next=${encodeURIComponent(path)}${signInView}`;
}