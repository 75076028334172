const ReviewableProducts = () => import(/* webpackChunkName: "ReviewableProducts" */ './ReviewableProducts');
const GenericProductReview = () => import(/* webpackChunkName: "GenericProductReview" */ './GenericProductReview');

export default [
  {
    path: '/product-review',
    name: 'ReviewableProducts',
    component: ReviewableProducts,
    meta: {
      noScroll: true
    },
    props: true,
    scrollBehavior(to) {
      // allow scroll to anchor
      if (to.hash) {
        return {
          selector: to.hash
        };
      }
    } 
  },
  {
    path: '/product-review/:urlKey',
    name: 'ProductReview',
    component: GenericProductReview,
    meta: {
      noScroll: true
    },
    props: true,
    scrollBehavior(to) {
      // allow scroll to anchor
      if (to.hash) {
        return {
          selector: to.hash
        };
      }
    } 
  }
];
