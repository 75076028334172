import mrApi from './mrApi';

export default {
  getLocation,
  getLocationReviews,
  getActiveLocationListForRegion,
  getAppointment,
  customerHasLateCancelation,
  getClosestLocationsByLatLong,
  getClosestLocationsByAddress,
  getClosestLocationsByCustomerAddress,
  getClosestLocationsByIp,
  getClosestLocationsByLocationId,
  getRegion,
  getAllRegions,
  getClosestRegion,
  getTheFirstAvailableDate,
  getFirstAvailableDates,
  getAvailableDates,
  getOnlineBookingTimes,
  getBookingConfirmation,
  updateReservationNotes,
  createHoldIncludeAlternates,
  saveCustomerDetails,
  applyServicePromo,
  loadCustomerUpcomingAppointments,
  loadAppointmentBookingProgress,
  saveAppointmentProgressToSession,
  cancelAppointment,
  createAppointment,
  checkWaitlistForDuplicate,
  createWaitlistEntry,
  preSaveBookingInfo,
  getIfHoldIsValid,
  getActiveLocationList,
  getActiveLocationsListForMapView,
  getActiveWholesalesLocationsList,
  getServicesList,
  getAddonTreatmentsForLocation,
};

export function getLocationReviews(params) {
  return mrApi.get('/api/colorbar/getLocationReviews', { params });
}

export function getLocation(params) {
  return mrApi.get('/api/colorbar/getLocation', { params });
}

export function getAddonTreatmentsForLocation(params) {
  return mrApi.get('/api/colorbar/getAddonTreatmentsForLocation', { params });
}


export function getActiveLocationList(params) {
  return mrApi.get('/api/colorbar/getActiveLocationList', { params });
}

export function getActiveLocationsListForMapView(params) {
  return mrApi.get('/api/colorbar/getActiveLocationsListForMapView', { params });
}

export function getActiveLocationListForRegion(params) {
  return mrApi.get('/api/colorbar/getActiveLocationListForRegion', { params });
}

export function getAppointment(params) {
  return mrApi.get('/api/colorbar/getAppointment', { params });
}

export function getAllCustomerReservations() {
  return mrApi.get('/api/colorbar/getAllCustomerReservations');
}

export function customerHasLateCancelation() {
  return mrApi.get('/api/colorbar/customerHasLateCancelation');
}

export function getClosestLocationsByLatLong(params) {
  return mrApi.get('/api/colorbar/getClosestLocationsByLatLong', { params });
}

export function getClosestLocationsByAddress(params) {
  return mrApi.get('/api/colorbar/getClosestLocationsByAddress', { params });
}

export function getClosestLocationsByCustomerAddress(params) {
  return mrApi.get('/api/colorbar/getClosestLocationsByCustomerAddress', { params });
}

export function getClosestLocationsByIp(params) {
  return mrApi.get('/api/colorbar/getClosestLocationsByIp', { params });
}

export function getClosestLocationsByLocationId(params) {
  return mrApi.get('/api/colorbar/getClosestLocationsByLocationId', { params });
}

export function getRegion(params) {
  return mrApi.get('/api/colorbar/getRegion', { params });
}

export function getAllRegions() {
  return mrApi.get('/api/colorbar/getAllRegions');
}

export function getClosestRegion() {
  return mrApi.get('/api/colorbar/getClosestRegion');
}

export function getTheFirstAvailableDate(params) {
  return mrApi.get('/api/colorbar/getTheFirstAvailableDate', { params });
}

export function getFirstAvailableDates(params) {
  return mrApi.get('/api/colorbar/getFirstAvailableDates', { params });
}

export function getAvailableDates(params) {
  return mrApi.get('/api/colorbar/getAvailableDates', { params });
}

export function getOnlineBookingTimes(params) {
  return mrApi.get('/api/colorbar/getOnlineBookingTimes', { params });
}

export function getBookingConfirmation(params) {
  return mrApi.get('/api/colorbar/getBookingConfirmation', { params });
}

export function updateReservationNotes(params) {
  return mrApi.post('/api/colorbar/updateReservationNotes', params);
}

export function getIfHoldIsValid(params) {
  return mrApi.get('/api/colorbar/getIfHoldIsValid', { params });
}

export function createHoldIncludeAlternates(params) {
  return mrApi.get('/api/colorbar/createHoldIncludeAlternates', { params });
}

export function saveCustomerDetails(params) {
  return mrApi.post('/api/colorbar/saveCustomerDetails', params);
}

export function applyServicePromo(params) {
  return mrApi.post('/api/colorbar/applyServicePromo', params);
}

export function loadCustomerUpcomingAppointments(params) {
  return mrApi.get('/api/colorbar/loadCustomerUpcomingAppointments', { params });
}

export function loadAppointmentBookingProgress(params) {
  return mrApi.get('/api/colorbar/loadAppointmentBookingProgress', { params });
}

export function saveAppointmentProgressToSession(params) {
  return mrApi.post('/api/colorbar/saveAppointmentProgressToSession', params);
}

export function createAppointment(params) {
  return mrApi.post('/api/colorbar/createAppointment', params);
}

export function cancelAppointment(params) {
  return mrApi.post('/api/colorbar/cancelAppointment', params);
}

export function checkWaitlistForDuplicate(params) {
  return mrApi.post('/api/colorbar/checkWaitlistForDuplicate', params);
}

export function getActiveWholesalesLocationsList(params) {
  return mrApi.post('/api/colorbar/getActiveWholesalesLocationsList', params);
}

export function createWaitlistEntry(params) {
  return mrApi.post('/api/colorbar/createWaitlistEntry', params);
}

export function preSaveBookingInfo(params) {
  return mrApi.post('/api/colorbar/preSaveBookingInfo', params);
}

export function getServicesList(params) {
  return mrApi.get('api/colorbar/getServicesList', { params });
}