<template lang="pug">
  component.mrbtn(
    :is="tag"
    v-ripple="60"
    @click="handleClick"
    :class="btnClass"
    v-bind="attrs"
  )
    span.prepend-content(v-if="showPrepend")
      template(v-if="icon")
        mr-icon(:name="icon" height="1.2em")

      slot(name="prePendContent")

    span.btn-content(:class="{'fade': loading}")
      slot

    span.append-content(v-if="showAppend")
      template(v-if="iconAppend")
        mr-icon(:name="iconAppend" width="1.2em")

      slot(name="appendContent")

    template(v-if="loading")
      span.spinner-wrap.xs-f-large
        mr-icon(name="spinner-v2-white" original).spinner
</template>

<script>
  export default {
    name: "MrBtn",

    props: {
      tag: {
        type: String,
        default: "button", // Valor por defecto
      },
      icon: {
        type: String,
        required: false,
      },
      iconAppend: {
        type: String,
        required: false,
      },
      round: {
        type: Boolean,
        default: false,
      },
      secondary: {
        type: Boolean,
        default: false,
      },
      tertiary: {
        type: Boolean,
        default: false,
      },
      light: {
        type: Boolean,
        default: false,
      },
      white: {
        type: Boolean,
        default: false,
      },
      whiteInverse: {
        type: Boolean,
        default: false,
      },
      fixedWidth: {
        type: Boolean,
        default: false,
      },
      block: {
        type: Boolean,
        default: false,
      },
      xxsmall: {
        type: Boolean,
        default: false,
      },
      xsmall: {
        type: Boolean,
        default: false,
      },
      small: {
        type: Boolean,
        default: false,
      },
      large: {
        type: Boolean,
        default: false,
      },
      disabled: {
        type: Boolean,
        default: false,
      },
      loading: {
        type: Boolean,
        default: false,
      },
      onClick: {
        type: Function,
        required: false,
      },
      noLeftPadding: {
        type: Boolean,
        required: false,
      },
      noRightPadding: {
        type: Boolean,
        required: false,
      },
      noUppercase: {
        type: Boolean,
        required: false,
      },
    },

    computed: {
      btnClass() {
        return {
          "btn-round": this.round,
          "btn-secondary": this.secondary,
          "btn-tertiary": this.tertiary,
          "btn-light": this.light,
          "btn-white": this.white,
          "btn-white-inverse": this.whiteInverse,
          disabled: this.disabled || this.loading,
          "btn-fixed-width": this.fixedWidth,
          "btn-block": this.block,
          "xs-f-xxsmall": this.xxsmall,
          "xs-f-xsmall": this.xsmall,
          "xs-f-small": this.small,
          "xs-f-large": this.large,
          "max-at-tweak": this.xxsmall || this.xsmall || this.small || this.large,
          "no-left-padding": this.noLeftPadding,
          "no-right-padding": this.noRightPadding,
          "no-uppercase": this.noUppercase,
        };
      },

      showPrepend() {
        return Boolean(this.icon || !!this.$slots.prePendContent);
      },

      showAppend() {
        return Boolean(this.iconAppend || !!this.$slots.appendContent);
      },

      attrs() {
        const { onClick, tag, ...rest } = this.$props;
        return rest;
      },
    },

    methods: {
      handleClick(event) {
        if (!this.disabled && !this.loading) {
          this.$emit("click", event);
        }
      },
    },
  };

</script>

<style scoped lang="stylus">
  .mrbtn
    setcolor(white)
    background-color cta-color-1
    display flex
    align-items center
    justify-content center
    max-width max-content
    position relative
    white-space nowrap
    cursor pointer
    text-decoration none
    padding 0 1.5em
    transition background 400ms
    font-family f-primary-bold
    font-weight normal
    font-style normal
    border 2px solid cta-color-1
    transition(background-color 0.2s\, color 0.2s\, border-color 0.2s)
    border-radius 4px
    overflow hidden

    .btn-content
      opacity 1
      transition opacity 0.3s
      padding 1em 0
      line-height 1.3em !important
      letter-spacing 0.13em
      text-transform uppercase

      &.fade
        opacity 0.5
        transition opacity 0.5s

    .prepend-content
    .append-content
      display flex
      align-items center
      margin-inline 0.3em

    .spinner-wrap
      align-items center
      display flex
      height 100%
      justify-content center
      left 0
      position absolute
      top 0
      width 100%

    .spinner
      border none
      height 1.5em
      -webkit-animation spin 1s linear infinite
      -moz-animation spin 1s linear infinite
      animation spin 1s linear infinite
      animation-direction reverse

    &.no-uppercase
      .btn-content
        text-transform unset

    &.btn-fixed-width
      width 23em
      max-width revert

    &.btn-block
      max-width revert
      width 100%

      .btn-content
        display block

    &:hover
    &:active
    &:focus
      setcolor(color-white)

    &:focus
      border-color darken(cta-color-1, 20%)
      background-color darken(cta-color-1, 20%)

    &:hover
      border-color darken(cta-color-1, 10%)
      background-color darken(cta-color-1, 10%)

    &.disabled,[disabled]
      setcolor(color-disabled)
      background-color cta-color-3 !important
      border 2px solid cta-color-3 !important
      pointer-events none !important
      transition(background-color 0.2s\, color 0.2s\, border-color 0.2s)

    &.btn-secondary // REAL secondary btn USE THIS
    &.btn-inverse // Deprecated DO NOT USE
    &.btn-transparent // Deprecated DO NOT USE
      setcolor(cta-color-4)
      background-color ui-color-1
      border 2px solid cta-color-4

      &:hover
      &:active
      &:focus
        setcolor(color-white)
        background-color cta-color-4

      &.disabled
        background-color ui-color-1 !important
        setcolor(cta-color-3)

    &.btn-white
      setcolor(brand-color-5)
      background-color color-white
      border 2px solid color-white
      &:hover
      &:active
      &:focus
        background-color color-white
        border-color color-white
        setcolor(brand-color-5)

    &.btn-white-inverse
      setcolor(color-white)
      background-color transparent
      border 2px solid color-white
      &:hover
      &:active
      &:focus
        background-color color-white
        border-color color-white
        setcolor(brand-color-5)

    &.btn-tertiary
      setcolor(cta-color-1)
      background-color ui-color-1
      border none

      &:hover
      &:focus
        background-color ui-color-4

      &.disabled
        background-color ui-color-1 !important
        border none !important
        setcolor(cta-color-3)

    &.btn-light
      setcolor(brand-color-8)
      background-color ui-color-13
      border none

      &:hover
      &:focus
        background-color ui-color-5
        setcolor(brand-color-8)

      &:active
        background-color ui-color-5
        setcolor(brand-color-8)

      &.disabled
        background-color ui-color-1 !important
        border 2px solid ui-color-1 !important
        setcolor(cta-color-3)

    &.btn-round
      border-radius 3em

    // Depricated button styles
    &.btn-transparent
      background-color transparent
      border 2px solid color-white
      setcolor(color-white)

      &:hover
      &:focus
        setcolor(cta-color-1)
        background-color color-white

      &:active
        background-color transparent

      &.disabled
        background-color transparent
        setcolor(color-white)

    &.btn-accent
      setcolor(white)
      background-color color-accent
      border none

      &:hover
      &:focus
        background-color color-accent-light
        setcolor(white)

      &:active
        background-color color-accent-dark
        setcolor(white)
        outline 0

    &.btn-wrap
      white-space normal

    &.no-left-padding
      padding-left 0

    &.no-right-padding
      padding-right 0

    .btn-xs
      font-size font-size-xsmall

    .btn-sm
      font-size font-size-small

    .btn-lg
      font-size font-size-large

</style>
