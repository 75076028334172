<template lang="pug">
  .site-nav-desktop-v2(v-click-outside="closeNav")
    ul.site-nav-wrap(itemscope itemtype="https://schema.org/SiteNavigationElement" role="menubar")
      li.site-nav-item(v-if="shopNav" role="none")
        button.site-nav-title.btn-reset.upper.semi-wide-letter-spacing.xs-f-small.md-f-medium.lg-f-xsmall.max-at-tweak(@click="toggleNav(shopNav)" :class="{'selected': openedNav == shopNav.title}")
          .site-nav-title-content(tabindex="-1")
            | {{ shopNav.title }}
            i.icon-caret-down(:class="{'rotate': openedNav == shopNav.title }")
              span.ada-tooltip nav {{ openedNav == shopNav.title ? 'open' : 'closed' }}

        transition(name="fade-block")
          .site-nav-desktop-drawer.site-nav-shop-drawer(v-if="openedNav == shopNav.title")
            SiteNavDesktopV2ShopNav(:shop-nav="shopNav")

      li.site-nav-item(v-if="navItems" v-for="(item, index) in navItems" :key="'nav-item' + index" role="none")
        button.site-nav-title.btn-reset.upper.semi-wide-letter-spacing.xs-f-small.md-f-medium.lg-f-xsmall.max-at-tweak(@click="toggleNav(item)" :class="{'selected': openedNav == item.title}")
          .site-nav-title-content(tabindex="-1")
            | {{ item.title }}&nbsp;
            i.icon-caret-down(:class="{'rotate': openedNav == item.title }")
              span.ada-tooltip nav {{ openedNav == item.title ? 'open' : 'closed' }}

        transition(name="fade-block")
          .site-nav-desktop-drawer(v-if="openedNav == item.title")
            SiteNavDesktopV2SubNav(:sub-navs="item.subNavs")

      li.site-nav-item(v-if="aboutNav" role="none")
        button.site-nav-title.btn-reset.upper.semi-wide-letter-spacing.xs-f-small.md-f-medium.lg-f-xsmall.max-at-tweak(@click="toggleNav(aboutNav)" :class="{'selected': openedNav == aboutNav.title}")
          .site-nav-title-content(tabindex="-1")
            | {{ aboutNav.title }}
            i.icon-caret-down(:class="{'rotate': openedNav == aboutNav.title }")
              span.ada-tooltip nav {{ openedNav == aboutNav.title ? 'open' : 'closed' }}

        transition(name="fade-block")
          .site-nav-desktop-drawer.about-nav-drawer(v-if="openedNav == aboutNav.title")
            SiteNavDesktopV2AboutNav(:about-nav="aboutNav")

      li.site-nav-item(v-if="navLinks" v-for="(navLink) in navLinks" role="none")
        a.site-nav-title.upper.semi-wide-letter-spacing.xs-f-small.md-f-medium.lg-f-xsmall.max-at-tweak(v-if="validNavLinkHref(navLink)" :href="navLink.link.href" role="menuitem")
          .site-nav-title-content(v-if="validNavLinkText(navLink)" tabindex="-1")
            | {{ navLink.link.text }}

      li.site-nav-item(role="none")
        .site-nav-title.upper.semi-wide-letter-spacing.xs-f-small.md-f-medium.lg-f-xsmall.max-at-tweak
          span.site-nav-title-content(tabindex="-1")
            span#extole_zone_global_header

</template>

<script>
  import { extoleHeaderTag } from "@services/extoleSvc";
  import SiteNavTrackMixin from "@components/SiteNav/SiteNavTrackMixin/SiteNavTrackMixin";
  import SiteNavDesktopV2ShopNav from './SiteNavDesktopV2ShopNav';
  import SiteNavDesktopV2SubNav from './SiteNavDesktopV2SubNav';
  import SiteNavDesktopV2AboutNav from "./SiteNavDesktopV2AboutNav";

  export default {
    name: "SiteNavDesktopV2",

    mixins: [SiteNavTrackMixin],

    components: {
      SiteNavDesktopV2ShopNav,
      SiteNavDesktopV2SubNav,
      SiteNavDesktopV2AboutNav,
    },

    data() {
      return {
        openedNav: null
      };
    },

    props: {
      shopNav: {
        type: Object
      },
      navItems: {
        type: Array
      },
      navLinks: {
        type: Array
      },
      aboutNav: {
        type: Object
      },
    },

    methods: {
      toggleNav(navItem) {
        if (this.openedNav == navItem.title) {
          this.openedNav = null;
        } else {
          this.openedNav = navItem.title;
          this.mix_trackFTVNavViewed(navItem.title);
        }
      },

      closeNav() {
        this.openedNav = null;
      },

      validNavLinkHref(navLink) {
        return navLink?.link?.href?.length > 0;
      },

      validNavLinkText(navLink) {
        return navLink?.link?.text?.length > 0;
      },
    },

    mounted() {
      extoleHeaderTag();
    },
  };
</script>

<style scoped lang="stylus">
.site-nav-desktop-v2
  padding-bottom 1px

  .site-nav-item
    display inline-block
    vertical-align text-bottom
    color text-color-1
    border 1px solid transparent

    button
      background-color transparent
      border none
      padding 0

    .site-nav-title-content
      display block
      padding 0.5em 1.2em

  .site-nav-item
    .site-nav-title
      background-color transparent
      border none
      position relative
      padding 0

      &:focus
        outline -webkit-focus-ring-color auto 5px

      .site-nav-title-content
        display block

        &:focus
          outline none

      &:before
      &:after
        content ''
        position absolute
        bottom -2px
        height 3px
        left 1.25em
        width calc(99% - 2.5em)
        z-index 101
        background-color transparent
        transition(background 0.35s ease-in-out)

      &.selected
        &:after
          background-color brand-color-2

    a
      display block
      color text-color-1

    a
    button
      background-color transparent
      border none
      padding 0

    .site-nav-title-content
      padding 0.5em 1.20em

      .icon-caret-down
        cursor pointer

        &:before
          transition transform 350ms ease

        &.rotate
          &:before
            transform rotate(-180deg)

    .site-nav-shop-drawer
      padding 0!important
      position absolute!important
      left calc(50% - 50vw)!important
      width 100vw!important
      border-radius 0 0 1.25rem 1.25rem
      z-index 1000!important

    .site-nav-desktop-drawer
      position absolute
      background white
      width 100%
      left 0
      margin-top 4px
      padding 0em 5em
      box-shadow 0 2px 4px 0 rgba(0, 0, 0, 0.1)
      border-top 1px solid ui-color-4

      &.shop-drawer
        padding 1.5em 3em 0.5em

      &.open
        opacity 1
        display block

      .site-nav-desktop-sub-menu
        width 17em
        display inline-block

    @media screen and (min-width: 1080px)
      .site-nav-desktop-drawer
        padding 0 8%

    @media screen and (min-width: 1300px)
      .site-nav-desktop-drawer
        padding 0 10.3em

</style>
