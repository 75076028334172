import constants from '@components/SiteNav/constants';
import dataToolSvc from '@services/dataToolSvc';

const state = {
  navItems: [
    { url: '/dashboard/welcome', text: 'Welcome' },
    //- subscriptions item gets inserted dynamically from mrVueApp using addMenuItem
    //- memberships item gets inserted dynamically from mrVueApp using addMenuItem
    { url: '/dashboard/membership', text: 'Membership' },
    { url: '/dashboard/shop', text: 'Shop' },
    { url: '/dashboard/account', text: 'Account' },
    { url: '/colorbar', text: 'Hair Color Bar', out: true },
    { url: '/dashboard/how-to', text: 'How To' },
    { url: '/refer', text: 'Get $15', out: true },
    { url: '/blog', text: 'Blog', out: true },
  ],
  accountNavOpen: false,
  accountNavItems: null,
  topNavSelected: '',
  topNavMobileNavOpen: false,
  searchOpen: false,
  selectedSiteDesktopNav: 0,
  aboutNavExtraConfig: null,

  siteNavMobileNavOpen: false,
  breadcrumbs: [],
  currentMobileNav: 'TOP_NAV',
  mobileNavSlideAnimation: "fade-slide-left",
  mobileTopMenuFocusRef: null,
  dynamicShopNav: null,
  dynamicNavItems: null,
  dynamicNavLinks: null,
};

const getters = {
  currentMobileNavData(state) {
    return constants[state.currentMobileNav];
  },

  showMensMenu(state, getters, rootState, rootGetters) {
    if (process.env.IS_BROWSER) {
      let show = false;
      let pathname = window.location.pathname;
      if (rootState.cms.cmsSettings.showMensMenu || (pathname == '/cart' && rootGetters['cart/cartContainsOnlyMensProducts'])) {
        show = true;
      }

      return show;
    } else {
      return false;
    }
  },

};

const actions = {
  loadDynamicNav({ commit }) {
    return new Promise((resolve, reject) => {
      dataToolSvc.getData({ mixinKey: 'top-nav' }).then(res => {
        commit('setDynamicShopNav', res.data.ShopNav);
        commit('setDynamicNavItems', res.data.NavItems);
        commit('setDynamicNavLinks', res.data.NavLinks);
        commit('setAboutNavExtraConfig', res.data.AboutNav);
        resolve();
      }).catch(err => {
        reject(err);
      });
    });
  },

  toggleAccountNav({ state, commit }) {
    commit('setAccountNavOpen', !state.accountNavOpen);
  },

  toggleMobileSiteNav({ state, commit }) {
    commit('setSiteNavMobileNavOpen', !state.siteNavMobileNavOpen);

    if (state.siteNavMobileNavOpen) {
      if (state.mobileTopMenuFocusRef) {
        state.mobileTopMenuFocusRef.focus();
      }
      commit('setCurrentMobileNav', 'TOP_NAV');
    }
  },

  navigateTo({ state, commit, dispatch }, params) {
    commit('setMobileNavSlideAnimation', 'fade-slide-left');
    const { subNav } = params;
    dispatch('addBreadcrumb', state.currentMobileNav);
    commit('setCurrentMobileNav', subNav);
  },

  goBack({ state, commit, dispatch }) {
    commit('setMobileNavSlideAnimation', 'fade-slide-right');
    let previousNav = state.breadcrumbs[state.breadcrumbs.length - 1];
    commit('setCurrentMobileNav', previousNav);
    dispatch('removeBreadcrumb');
  },

  addBreadcrumb({ state, commit }, crumb) {
    if (crumb) {
      let newCrumbs = [...state.breadcrumbs];
      newCrumbs.push(crumb);
      commit('setBreadcrumbs', newCrumbs);
    }
  },

  removeBreadcrumb({ state, commit }) {
    let newCrumbs = state.breadcrumbs.slice(0, -1);
    commit('setBreadcrumbs', newCrumbs);
  },

  fetchAccountMenuItems({ commit }) {
    dataToolSvc.getData({ mixinKey: 'account-drawer' }).then(res => {
      commit('setAccountMenuItems', res.data);
    });

  },

  toggleSearch({ state, commit }) {
    commit('setSearchOpen', !state.searchOpen);
  },
};

const mutations = {
  setAboutNavExtraConfig(state, val) {
    state.aboutNavExtraConfig = val;
  },

  setDynamicShopNav(state, val) {
    state.dynamicShopNav = val;
  },
  setDynamicNavItems(state, val) {
    state.dynamicNavItems = val;
  },
  setDynamicNavLinks(state, val) {
    state.dynamicNavLinks = val;
  },

  addMenuItem(state, val) {
    //- make sure it exists, has url and is not in there already
    if (!val || !(val.url || val.method) || state.navItems.some(item => item.url == val.url)) {
      return;
    }

    let position = val.index || state.navItems.length;
    state.navItems.splice(position, 0, val);
  },

  removeMenuItem(state, name) {
    state.navItems = state.navItems.filter(item => item.text !== name);
  },

  setSelectedSiteDesktopNav(state, val) {
    if (state.selectedSiteDesktopNav == val) {
      val = 0;
    }

    state.selectedSiteDesktopNav = +val;
  },

  setSiteNavMobileNavOpen(state, val) {
    state.siteNavMobileNavOpen = val;
  },

  setCurrentMobileNav(state, val) {
    state.currentMobileNav = val;
  },

  setBreadcrumbs(state, val = []) {
    state.breadcrumbs = val;
  },
  setMobileNavSlideAnimation(state, val) {
    state.mobileNavSlideAnimation = val || "fade-slide-left";
  },

  setMobileTopMenuFocusRef(state, val) {
    state.mobileTopMenuFocusRef = val;
  },

  setTopNavSelected(state, val) {
    if (state.topNavSelected == val) {
      val = 0;
    }

    state.topNavSelected = +val;
  },

  setTopNavMobileNavOpen(state, val) {
    state.topNavMobileNavOpen = val;
  },

  setSearchOpen(state, val) {
    state.searchOpen = Boolean(val);
  },

  setAccountMenuItems(state, val) {
    state.accountNavItems = val;
  },

  setAccountNavOpen(state, val) {
    state.accountNavOpen = val;
  }

};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
