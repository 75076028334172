import commonChatWidgetMixin from "./commonChatWidgetMixin";
import { mapState, mapActions } from 'vuex';
import dataToolSvc from '@services/dataToolSvc';
import vueCustomerSvc from "@services/vueCustomerSvc";
import { match } from "path-to-regexp";

function glob(pattern, input) {
  // eslint-disable-next-line no-undef
  const pat = match(pattern);
  const ret = pat(input);
  return ret;
}

function cleanObject(obj) {
  if (obj) {
    Object.keys(obj).forEach(key => {
      if (obj[key] && typeof obj[key] === 'object') {
        cleanObject(obj[key]);
        if (Object.keys(obj[key]).length == 0) {
          delete obj[key];
        }
      } else {
        if (!obj[key]) {
          delete obj[key];
        }
      }
    });
  }
}

function getConfig(url, data) {
  const blacklist = data.blacklist;
  for (let black of blacklist) {
    if (glob(black, url)) {
      return;
    }
  }
  let finalConfig = data.defaultConfig;

  for (let route of data.perRoute) {
    for (let path of route.path) {
      if (glob(path, url)) {
        finalConfig = route.config;
      }
    }
  }

  data.perRoute.forEach(route => {
    route.path.forEach(path => {
      if (glob(path, url)) {
        finalConfig = route.config;
      }
    });
  });

  cleanObject(finalConfig);
  if (finalConfig?.launcher?.customStyle) {
    try {
      finalConfig.launcher.customStyle = JSON.parse(finalConfig.launcher.customStyle);
    } catch (err) {
      delete finalConfig.launcher.customStyle;
    }
  }
  return finalConfig;
}

export default {
  data() {
    return {
      sierraConfig: {},
      subscriptionConfig: {},
      membershipConfig: {},
      tokenData: null,
    };
  },
  mixins: [commonChatWidgetMixin],

  created() {
    this.initSierraChatSettings();
  },

  mounted() {
    this.mix_loadSierraConfig();
  },

  methods: {
    ...mapActions("systemSettings", ['initSierraChatSettings']),

    mix_loadSierraConfig() {
      if (this.sierraChatSettingsLoaded && this.sierraChatSettings.enabled && this.sierraExperiment) {
        const promises = [dataToolSvc.getData({
          mixinKey: 'sierra_config'
        }),
        vueCustomerSvc.getSierraJWTToken()
        ];

        Promise.all(promises).then(res => {
          const [configRes, tokenRes] = res;
          this.subscriptionConfig = configRes.data.cancelSubscription;
          this.membershipConfig = configRes.data.cancelMembership;
          if (tokenRes?.data?.token) {
            this.tokenData = tokenRes.data;
          }
          cleanObject(this.subscriptionConfig);
          cleanObject(this.membershipConfig);
          const config = getConfig(window.location.pathname, configRes.data);
          if (!config) {
            return;
          }
          if (tokenRes?.data?.token) {
            config.secrets = {
              JWT_TOKEN: tokenRes.data.token
            };
          }

          this.sierraConfig = config;
          window.sierraConfig = config;
          this.mix_addChatWidgetLoadScript();
        });
      }
    },

    mix_cancelSubscription(subId) {
      const payload = {
        ...this.subscriptionConfig,
        variables: {
          SUBSCRIPTION_ID: subId
        }
      };
      if (this.tokenData?.token) {
        payload.secrets = {
          JWT_TOKEN: this.tokenData.token
        };
      }
      this.mix_openCleanSierraChat(payload);
    },

    mix_cancelMembership(subId) {
      const payload = {
        ...this.membershipConfig,
        variables: {
          SUBSCRIPTION_ID: subId
        }
      };
      if (this.tokenData?.token) {
        payload.secrets = {
          JWT_TOKEN: this.tokenData.token
        };
      }

      this.mix_openCleanSierraChat(payload);
    },

    initChat(options) {
      if (this.mix_isMainScriptLoaded) {
        window.sierra.cleanup();
        window.sierra.init(options);
      }

    },

    mix_configureChatWidgetLoadScript(script) {
      window.sierraConfig = { ...this.sierraConfig };

      script.setAttribute('src', this.script);
      script.setAttribute('type', 'module');
      return script;
    },

    mix_isMainScriptLoaded() {
      return !!window.sierra;
    },

    mix_doOpenChat() {
      window.sierra.openChatModal();
    },

    mix_openCleanSierraChat(options) {
      if (this.mix_isMainScriptLoaded) {
        window.sierra.cleanup();
        window.sierra.init(options);
        window.sierraConfig = options;
        this.mix_openChat();
      }
    },

  },

  watch: {
    sierraChatSettingsLoaded: function(newValue) {
      if (newValue) {
        this.mix_loadSierraConfig();
      }
    }
  },

  computed: {
    ...mapState('global', ['MRConfig']),
    ...mapState('systemSettings', ['sierraChatSettingsLoading', 'sierraChatSettingsLoaded', 'sierraChatSettings']),

    sierraExperiment() {
      return this.experiments?.sierraAiExperiment === "B";
    },

    script() {
      let script = this.MRConfig?.sierraScript;
      return script || '';
    }
  }
};