<template lang="pug">
  .site-nav-desktop-v2-sub-nav
    .sub-nav(v-for="nav in subNavs" :key="nav.title")
      .nav-title.bold.upper.xs-f-medium.lg-f-small.max-at-tweak.brand-color-1 {{ nav.title }}
      ul(role="menu")
        li.sub-menu-item.text-color-1.xs-f-medium.lg-f-small.max-at-tweak(v-for="(item) in nav.navItems" :key="item.title + item.url" role="none")
          a(:href="item.url" role="menuitem" @click="mix_trackItemClick(item.title)" :aria-label="item.ariaLabel || item.title").site-nav-sub-menu-item
            .main-title.text-color-1.xs-f-medium.lg-f-small.max-at-tweak {{ item.title }}
            .sub-title.text-color-3.xs-f-xsmall.max-at-tweak(v-if="item.subTitle") {{ item.subTitle }}
    .marketing-placement(v-if="marketingPlacement")
      CMSPartialSsr(:mixin-key="marketingPlacement.mixin_key")
</template>

<script>
  import SiteNavTrackMixin from "@components/SiteNav/SiteNavTrackMixin/SiteNavTrackMixin";
  import CMSPartialSsr from '@components/CmsPartialSsr';

  export default {
    name: "SiteNavDesktopV2AboutNav",

    components: {
      CMSPartialSsr,
    },

    mixins: [SiteNavTrackMixin],

    props: {
      aboutNav: {
        type: Object,
        required: true,
      }
    },

    computed: {
      subNavs() {
        return this.aboutNav?.categories ?? [];
      },

      marketingPlacement() {
        return this.aboutNav?.marketingPlacement ?? null;
      }
    }
  };
</script>

<style scoped lang="stylus">
.site-nav-desktop-v2-sub-nav
  display flex
  flex-direction row
  flex-wrap wrap
  justify-content left

  .marketing-placement
    display flex
    justify-content flex-end
    padding 1em
    height 100%
    width 100%
    max-width 250px

  .sub-nav
    text-align left
    padding 1em
    min-width 17em

    .sub-menu-item
      padding 0.5em 0

</style>
